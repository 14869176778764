import React from 'react'
import { ListView, Modal, Toast, PullToRefresh } from 'antd-mobile'
import WorkOrderItem from '../../components/WorkOrderItem'
import { ORDER_STATUS, PAY_STATUS } from '../../lib/constants'
import * as deliveryApi from '../../lib/API/delivery'
import * as workOrderApi from '../../lib/API/workOrder'

export default class TaskList extends React.Component {

  constructor(props) {
    super(props);

    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1.pkid !== row2.pkid,
    })

    this.originalData = []

    this.state = {
      dataSource,
      isLoading: true,
      page: 1,
      pageSize: 10000,
      keywords: '',
      hasMore: true,
      refreshing: false,
    }
  }

  componentDidMount() {
    this.initData()
  }

  // 初始化数据。用于在组件挂载后及结束配送后重新初始化整个页面的所有状态
  initData = () => {
    this.getData(1)
      .then(data => {
        if (!data) return
        this.originalData = data.rows
        this.setState({
          page: data.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(this.originalData),
          isLoading: false,
          hasMore: data.rows.length >= this.state.pageSize,
          refreshing: false,
        })
      })
  }


  getData = (page) => {
    Toast.loading('加载中...', 0)
    this.setState({ isLoading: true })

    let respPromise
    if (this.props.type === ORDER_STATUS.PENDING) {
      respPromise = deliveryApi.getPendingTasks(this.props.pkid, page, this.state.pageSize, this.props.keywords)
    } else {
      respPromise = deliveryApi.getDoneTasks(this.props.pkid, page, this.state.pageSize, this.props.keywords)
    }

    return respPromise.then(resp => {
      Toast.hide()
      if (resp.isOK) {
        return resp.data
      } else {
        this.setState({ isLoading: false, refreshing: false })
        Toast.hide()
        Toast.fail(resp.errorMessage, 2)
      }
    })
  }

  onEndReached = () => {
    if (this.state.isLoading || !this.state.hasMore) {
      return
    }

    this.getData(this.state.page + 1)
      .then(data => {
        if (!data) return
        this.originalData = [...this.originalData, ...data.rows]
        this.setState({
          page: data.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(this.originalData),
          isLoading: false,
          hasMore: data.rows.length >= this.state.pageSize,
          refreshing: false,
        })
      })
  }

  onDoDelivery = (workOrder) => {
    const closeModal = Modal.alert('提示', `是否确认开始前往 ${workOrder.customerAddress} 送货？`, [
      { text: '取消', onPress: () => console.log('cancel') },
      {
        text: '确定', onPress: () => {
          Toast.loading('加载中...', 0)
          workOrderApi.startDelivery(workOrder.pkid)
            .then((resp) => {
              if (resp.isOK) {
                Toast.hide()
                closeModal.close()
                this.props.history.push(`/work-order/${workOrder.pkid}`)
              } else {
                Toast.hide()
                Toast.fail(resp.errorMessage, 2)
              }
            })
        }
      },
    ])
  }

  onDoReceive = (workOrder) => {
    if (workOrder.payStatus === PAY_STATUS.NONE) {
      Modal.alert('提示', '结账未完成前不可进行确认送达操作，是否前往记账？', [
        { text: '取消', onPress: () => console.log('cancel') },
        { text: '确定', onPress: () => this.props.history.push(`/work-order/${workOrder.pkid}`) },
      ])
      return
    }

    Modal.alert('是否确认已送达以下地址', workOrder.customerAddress, [
      { text: '取消', onPress: () => console.log('cancel') },
      {
        text: '确定', onPress: () => {
          Toast.loading('加载中...', 0)
          workOrderApi.end(workOrder.pkid)
            .then(resp => {
              if (resp.isOK) {
                Toast.hide()
                Toast.success('配送完成', 2, () => {
                  typeof this.props.refreshData === 'function' && this.props.refreshData()
                })

              } else {
                Toast.hide()
                Toast.fail(resp.errorMessage, 2)
              }
            })
        }
      },
    ])
  }

  onRefresh = () => {
    this.setState({ refreshing: true, isLoading: true })
    this.getData(1)
      .then(data => {
        if (!data) return
        this.originalData = data.rows
        this.setState({
          page: data.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(this.originalData),
          isLoading: false,
          hasMore: data.rows.length >= this.state.pageSize,
          refreshing: false,
        })
      })
  }


  render() {
    const { dataSource, pageSize, refreshing, hasMore } = this.state

    if (!dataSource.getRowCount()) {
      return <div className="no-data">暂无数据</div>
    }

    const renderRow = (rowData) => {
      return <WorkOrderItem
        key={rowData.pkid}
        workOrder={rowData}
        onDoDelivery={this.onDoDelivery.bind(this)}
        onDoReceive={this.onDoReceive.bind(this)}
      />
    }

    return (
      <ListView
        ref={el => this.lv = el}
        dataSource={dataSource}
        renderRow={renderRow}
        renderFooter={() => <div style={{ textAlign: 'center' }}>{hasMore ? '加载更多' : '已经到底了'}</div>}
        pageSize={pageSize}
        initialListSize={pageSize}
        useBodyScroll={true}
        onEndReached={this.onEndReached}
        pullToRefresh={<PullToRefresh refreshing={refreshing} onRefresh={this.onRefresh} />}
      />
    )
  }
}

