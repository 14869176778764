import {Modal} from 'antd-mobile'
import {formatDateTime} from '../../lib/date'
import {formatPrice} from "../../lib/utils"

export default function SummaryDialog({visible, summary, onClose = null, onConfirm = null}) {
  // 配送开始和结束时间
  const startTime = summary.startTime ? formatDateTime(new Date(summary.startTime)) : '-'
  const endTime = formatDateTime(new Date())

  return (
    <Modal
      visible={ visible }
      className="summary-dialog"
      maskClosable={ true }
      closable={ true }
      transparent={ true }
      footer={[
        { text: '取消', onPress: onClose },
        { text: '结束配送', onPress: onConfirm }
        ]}
      onClose={ onClose }
    >
      <div className="total-summary">
        <div className="summary-item">
          <span className="title">总客户数</span>
          <span className="value">{ optional(summary.customerCountTotal, '-') }户</span>
        </div>
        <div className="summary-item">
          <span className="title">总配送金额</span>
          <span className="value">{ formatPrice(summary.moneyTotal) }元</span>
        </div>
      </div>

      <div className="summary-detail">
        <div className="summary-col">
          <div className="summary-item">
            <span className="title">未处理客户</span>
            <span className="value">{ optional(summary.customerCountWait, '-') }户</span>
          </div>
          <div className="summary-item">
            <span className="title">已处理客户</span>
            <span className="value">{ optional(summary.customerCountDelivered, '-') }户</span>
          </div>
          <div className="summary-item">
            <span className="title">有欠款客户</span>
            <span className="value">{ optional(summary.customerCountCredit, '-') }户</span>
          </div>
          <div className="summary-item">
            <span className="title">有退货客户</span>
            <span className="value">{ optional(summary.customerCountReturn, '-') }户</span>
          </div>
          <div className="summary-item">
            <span className="title">不配送客户</span>
            <span className="value">{ optional(summary.customerCountDelay, '-') }户</span>
          </div>
        </div>
        <div className="summary-col">
          <div className="summary-item">
            <span className="title">未处理金额</span>
            <span className="value">{ formatPrice(summary.moneyWait) }元</span>
          </div>
          <div className="summary-item">
            <span className="title">已收款金额</span>
            <span className="value">{ formatPrice(summary.moneyReceived) }元</span>
          </div>
          <div className="summary-item">
            <span className="title">欠款金额</span>
            <span className="value">{ formatPrice(summary.moneyCredit) }元</span>
          </div>
          <div className="summary-item">
            <span className="title">退货金额</span>
            <span className="value">{ formatPrice(summary.moneyReturn) }元</span>
          </div>
          <div className="summary-item">
            <span className="title">不配送金额</span>
            <span className="value">{ formatPrice(summary.moneyDelay) }元</span>
          </div>
        </div>
      </div>

      <div className="summary-time">
        <div className="summary-item">
          <span className="title">配送开始时间</span>
          <span className="value">{ startTime }</span>
        </div>
        <div className="summary-item">
          <span className="title">配送结束时间</span>
          <span className="value">{ endTime }</span>
        </div>
      </div>
    </Modal>
  )
}

function optional(value, defaultValue) {
  return typeof value === 'undefined' ? defaultValue : value
}
