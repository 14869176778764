
import React, { useEffect, useState } from 'react'
import { Card, Modal } from 'antd-mobile'
import ReactTouchEvents from 'react-touch-events'
import { formatPrice, getTotal } from "../../lib/utils"

import './../PaymentInfo/style.less'

export default function ReturnInfo({ returnEditable, title, placeholder, style, editable, showDateCalendar, returnGoodsInfo, onUpdateGood }) {

  const [subtotalAmount, setSubtotalAmount] = useState(0)
  const [focus, setFocus] = useState(false)

  useEffect(() => {
    if (focus) {
      scrollToBottom()
    }
  }, [focus])
  // 求准备退货总计
  useEffect(() => {
    let total = 0;
    (returnGoodsInfo || []).map(d => {
      total = total + getTotal(d.returnGoods, 'goodsPriceSubtotal')
    })
    setSubtotalAmount(total)
  }, [returnGoodsInfo])

  const scrollToBottom = () => {
    const scrollHeight = document.documentElement.scrollTop;
    document.documentElement.scrollTop = scrollHeight + 170;
  }

  const onKeypress = event => {
    if (event.charCode === 13 || event.charCode === 9) {
      event.target.blur()
    }
  }

  // 修改退货数量
  const onChangeQty = (e, pkid, date) => {
    const qty = e.target.value
    onUpdateGood && onUpdateGood(date, pkid, qty)
  }

  // 删除退货货品
  const onRemove = (date, pkid) => {
    Modal.alert('提示', '确定删除该退货货品？', [
      { text: '取消', onPress: () => console.log('cancel') },
      {
        text: '确定', onPress: () => {
          onUpdateGood && onUpdateGood(date, pkid)
        }
      }
    ])
  }

  return (
    <div style={{ marginBottom: focus ? 170 : 0 }}>
      <Card className="c-payment-info" style={style}>
        <Card.Header
          title={title}
          extra={<span className="card-extra">总计：￥{formatPrice(subtotalAmount)}</span>}
        />
        <Card.Body style={{ padding: '0' }}>
          <div>
            {
              // 不可编辑，并且无付款信息时
              !editable && !returnEditable && !returnGoodsInfo.length &&
              <div className="no-payment">
                <i className="no-payment-icon"></i>
                <div className="no-payment-text">{placeholder}</div>
              </div>
            }
            {
              (!!returnEditable || !!editable || returnGoodsInfo) && <>
                {/* 退货产品 */}
                <div className={`goods-detail-retrun ${!!returnEditable || !!editable ? '' : 'returned'}`}>
                  {
                    returnGoodsInfo.map(cate => {
                      return (
                        <section key={cate.date}>
                          <div className="section-title">{cate.date}</div>
                          <table>
                            <tbody>
                              {
                                cate.returnGoods.map((item, index) => {
                                  return [
                                    <tr key={'name' + index}>
                                      <td className="goods-name" colSpan="3">
                                        {
                                          !!returnEditable || !!editable ?
                                            <ReactTouchEvents touchClass="active">
                                              <i className="remove-btn iconfont icon-remove" onClick={() => { onRemove(cate.date, (item.workOrderlinePkid || item.pkid)) }}></i>
                                            </ReactTouchEvents>
                                            :
                                            ''
                                        }
                                        {item.goodsName}
                                        {item.lotNo ? ` (${item.lotNo})` : ''}
                                      </td>
                                    </tr>,
                                    <tr key={index}>
                                      <td>{'￥' + (item.goodsPrice || '-')}</td>
                                      <td align="right" style={{ minWidth: '40px' }}>
                                        {!!returnEditable || !!editable ?
                                          <input
                                            type="number"
                                            className="returnQty"
                                            value={item.goodsQty}
                                            onChange={(e) => onChangeQty(e, (item.workOrderlinePkid || item.pkid), cate.date)}
                                            onKeyPress={onKeypress}
                                            onFocus={() => { setFocus(true) }}
                                            onBlur={() => { setFocus(false) }}
                                            style={{ borderBottom: !item.goodsQty || item.goodsQty <= 0 ? '1px solid red' : '' }}
                                          />
                                          :
                                          item.goodsQty
                                        }
                                        {item.goodsUnit}</td>
                                      <td align="right">{'￥' + formatPrice(item.goodsPriceSubtotal)}</td>
                                    </tr>
                                  ]
                                })
                              }
                              {!!returnEditable || !!editable ? null :
                                <tr className='subtotal'>
                                  <td>合计</td>
                                  <td align="right">{getTotal(cate.returnGoods, 'goodsQty')}</td>
                                  <td align="right">{'￥' + formatPrice(getTotal(cate.returnGoods, 'goodsPriceSubtotal'))}</td>
                                </tr>
                              }
                            </tbody>
                          </table>
                        </section>
                      )
                    })
                  }
                </div>

                {!!returnEditable || !!editable ?
                  <div className="new-payment">
                    <ReactTouchEvents touchClass="active">
                      <i className="add-btn iconfont icon-plus" onClick={() => showDateCalendar && showDateCalendar(true)}></i>
                    </ReactTouchEvents>
                  </div> : ''
                }

              </>
            }
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}