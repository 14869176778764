import http from './http'
import {DIRECT_SALE_STORE_PAYMENT, BASIC_MEDICINE_RECEIPT_PAYMENT, PAYMENTS_NOT_DISPLAYED, GIFT_PAYMENT} from "./constants"

// 缓存的支付方式。以 ID 为 Key，原始值为 value
let paymentMethods = null
// 以数组表示的，接口返回的原始数据
let originalPaymentMethods = null
// 缓存的欠款收款方式
let creditPaymentMethods = null
let originalCreditPaymentMethods = null

export async function getPaymentMethodsFromServer() {
  return http.get('/drop-down/work-payment-type')
}

export async function getCreditPaymentMethodsFromServer() {
  return http.get('/drop-down/work-payment-type/credit')
}

export async function getPaymentMethods() {
  if (paymentMethods === null) {
    const resp = await getPaymentMethodsFromServer()
      .catch((err) => {
        console.error(err)
        return null
      })

    if (resp && resp.isOK) {
      originalPaymentMethods = resp.data.rows
      paymentMethods = {}

      originalPaymentMethods.forEach(item => {
        paymentMethods[item.pkid] = item
      })

    } else {
      paymentMethods = null
    }
  }

  return paymentMethods
}

export async function getCreditPaymentMethods() {
  if (creditPaymentMethods === null) {
    const resp = await getCreditPaymentMethodsFromServer()
      .catch((err) => {
        console.error(err)
        return null
      })

    if (resp && resp.isOK) {
      originalCreditPaymentMethods = resp.data.rows
      creditPaymentMethods = {}

      originalCreditPaymentMethods.forEach(item => {
        creditPaymentMethods[item.pkid] = item
      })

    } else {
      creditPaymentMethods = null
    }
  }

  return creditPaymentMethods
}

// 获取直营收款的 pkid，仅在已经初始化收款方式数据后有效
export function getPkidOfDirectSalePayment() {
  if (!originalPaymentMethods) return 0

  const payment = originalPaymentMethods.find(item => item.paymentTypeFeature === DIRECT_SALE_STORE_PAYMENT)
  return payment ? payment.pkid : 0
}

// 获取基药回执的 pkid
export function getPkidOfBasicMedicineReceiptPayment() {
  if (!originalPaymentMethods) return 0

  const payment = originalPaymentMethods.find(item => item.paymentTypeFeature === BASIC_MEDICINE_RECEIPT_PAYMENT)
  return payment ? payment.pkid : 0
}

export function getPkidOfPaymentsNotDisplayed() {
  if (!originalPaymentMethods) return []

  return originalPaymentMethods
    .filter(item => PAYMENTS_NOT_DISPLAYED.indexOf(item.paymentTypeFeature) >= 0)
    .map(item => item.pkid)
}

export function getPkidOfGiftPayments() {
  if (!originalPaymentMethods) return 0

  const payment = originalPaymentMethods.find(item => item.paymentTypeFeature === GIFT_PAYMENT)
  return payment ? payment.pkid : 0
}
