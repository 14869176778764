
const AUTH_CACHE_KEY = 'edms_auth'

// 于内存中保存 token 的副本，避免每次都从 localStorage 中读取，以提高效率
let token = null

export function getAuthInfo() {
  return token || window.localStorage.getItem(AUTH_CACHE_KEY) || ''
}

export function setAuthInfo(authorization) {
  token = authorization
  window.localStorage.setItem(AUTH_CACHE_KEY, authorization)
}

export function removeAuthInfo() {
  token = null
  window.localStorage.removeItem(AUTH_CACHE_KEY)
}
