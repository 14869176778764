import { Link } from 'react-router-dom'
import { Card, Toast } from 'antd-mobile'
import ReactTouchEvents from 'react-touch-events'
import { classname, makePhoneCall, normalizePhoneNumber, maskPhoneNumber } from '../../lib/utils'
import { ORDER_STATUS } from '../../lib/constants'
import { navigateTo } from "../../lib/amap"
import { formatDate } from '../../lib/date'
import './style.less'

export default function WorkOrderItem({ type, workOrder, onDoDelivery = null, onDoReceive = null }) {

  // 点击前往送货或确认收获按钮事件
  const onEnsureBtnClick = () => {
    Toast.loading('加载中...', 0.35, () => {
      if (workOrder.orderStatus === ORDER_STATUS.PENDING || (type === 'delay' && workOrder.orderStatus === ORDER_STATUS.TERMINATED)) {
        // 前往送货
        typeof onDoDelivery === 'function' && onDoDelivery(workOrder)

      } else {
        // 确认收货
        typeof onDoReceive === 'function' && onDoReceive(workOrder)
      }
    })
  }

  // 跳转到导航页
  const goToNavigate = () => {
    navigateTo(workOrder.customerName, workOrder.customerLongitude, workOrder.customerLatitude)
  }

  return (
    <Card className={classname({
      'c-task-item': true,
      'task-pending': workOrder.orderStatus === ORDER_STATUS.PENDING, // 待送货
      'task-terminated': workOrder.orderStatus === ORDER_STATUS.TERMINATED, // 今日不送货
      'task-processing': workOrder.orderStatus === ORDER_STATUS.PROCESSING, // 进行中
      'task-done': workOrder.orderStatus === ORDER_STATUS.DONE, // 已配送
    })}>
      <Card.Header
        thumb={<i className="task-status-icon"></i>}
        title={<div style={{ width: '100%' }}>
          {type === 'delay' ? <div>{formatDate(new Date(workOrder.taskDate))}</div> : ''}
          <div>{workOrder.orderStatusName}</div>
        </div>}
        extra={<Link className="task-link" onClick={() => {
          const scroll = document.documentElement.scrollTop
          window.localStorage.setItem('scrollY', scroll)
        }} to={'/work-order/' + workOrder.pkid}>查看详情</Link>}
      />
      <Card.Body style={{ padding: '12px 5px 0 15px' }}>
        <div className="task-detail">
          <i className="dot"></i>
          <div className="address">{workOrder.customerName}</div>
          {
            workOrder.customerLongitude && workOrder.customerLatitude &&
            <ReactTouchEvents touchClass="active" onTap={goToNavigate}>
              <i className="navigate iconfont icon-navigation"></i>
            </ReactTouchEvents>
          }
        </div>
        <div className="task-detail user-detail">
          <div className="user">{workOrder.customerContactPerson} &nbsp;&nbsp;&nbsp;{maskPhoneNumber(normalizePhoneNumber(workOrder.customerContactNumber))}</div>
          <ReactTouchEvents touchClass="active" onTap={makePhoneCall.bind(this, normalizePhoneNumber(workOrder.customerContactNumber))}>
            <i className="phone iconfont icon-phone"></i>
          </ReactTouchEvents>
        </div>
      </Card.Body>
      {/* 单子为配送单订单状态为待配送或者配送中/或者单子为未配送单(type === 'delay')配送时间<当前时间的今日不送货时显示按钮 */}
      {/* {
        (workOrder.orderStatus === ORDER_STATUS.PENDING || workOrder.orderStatus === ORDER_STATUS.PROCESSING || (type === 'delay' && showFlag)) &&
        <Card.Footer content={
          <ReactTouchEvents touchClass="active" onTap={onEnsureBtnClick}>
            <button className="task-action-btn">{workOrder.orderStatus === ORDER_STATUS.PENDING || (type === 'delay' && workOrder.orderStatus === ORDER_STATUS.TERMINATED) ? '前往送货' : '确认送达'}</button>
          </ReactTouchEvents>
        } />
      } */}
      {
        (workOrder.orderStatus === ORDER_STATUS.PENDING || workOrder.orderStatus === ORDER_STATUS.PROCESSING) &&
        <Card.Footer content={
          <ReactTouchEvents touchClass="active" onTap={onEnsureBtnClick}>
            <button className="task-action-btn">{workOrder.orderStatus === ORDER_STATUS.PENDING ? '前往送货' : '确认送达'}</button>
          </ReactTouchEvents>
        } />
      }

    </Card>
  )
}
