import axios from 'axios'
import {getAuthInfo, removeAuthInfo} from '../auth'

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/api-app' : '/api',
  timeout: 3e4,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  // 所有的返回状态码均不走 Reject，而是通过在 resolve 中统一处理
  validateStatus: function () {
    return true
  },
})

instance.interceptors.request.use(function(config) {
  // 每次请求发出前都重新读取授权信息，避免因授权信息变化导致后续请求错误
  config.headers.Authorization = getAuthInfo()
  return config
})

// 添加对响应是否成功的判断
instance.interceptors.response.use(function(response) {
  response.isOK = response.status >= 200 && response.status < 299 && response.data.opResult === 0
  return response
})

// 添加对常见错误码的处理，
// 并在 response 实例中添加 errorMessage 属性用于统一获取错误内容文本
instance.interceptors.response.use(function (response) {
  if (response.status >= 200 && response.status < 300) {
    if (response.data && response.data.opResult !== 0) {
      response.errorMessage = response.data.msg
    }
    return response
  }

  switch (response.status) {
    case 401:
      // 未登录
      // 跳转到登录页面，根据当前页面的不同会选择跳到不同的位置
      removeAuthInfo()

      if (window.location.hash !== '#/login') {
        window.location.href = '#/login'
      }

      response.errorMessage = '认证失效，请重新登录。'
      break

    case 403:
      // 无访问权限
      response.errorMessage = '没有访问权限'
      break

    default:
      response.errorMessage = (response.data && response.data.msg) || '网络故障，加载数据失败'
  }

  return response
}, function () {
  return {
    isOK: false,
    errorMessage: '网络故障，加载数据失败',
    data: {},
    status: 500,
  }
})

export default instance
