
// 订单状态
export const ORDER_STATUS = {
  PENDING: '10',
  PROCESSING: '20',
  TERMINATED: '30', // 中止（今日不送货）
  DONE: '40',
  CANCELED: '90',
}

// 配送总单状态
export const TASK_STATUS = {
  PENDING: '10',
  PROCESSING: '20',
  DONE: '40',
}

// 支付状态
export const PAY_STATUS = {
  NONE: '10', // 待记账
  HAS_DEBT: '20', // 支付但欠款
  DONE: '30', // 记账完成
}

// 商户类型
export const CUSTOMER_TYPES = {
  NORMAL: '10', // 一般客户（终端）
  BASIC_MEDICINE: '11', // 基药   客户记账的时候自动 填入付款类型 基药回执
  DIRECT_SALE_STORE: '20', // 直营店    自动 填入付款类型 直营收款
  FRANCHISEE_OUTLET: '21', //加盟店
}

// 直营收款的收款方式 ID
export const DIRECT_SALE_STORE_PAYMENT = '80'

// 基药回执的收款方式 ID
export const BASIC_MEDICINE_RECEIPT_PAYMENT = '70'

// 终端赠品的收款方式 ID
export const GIFT_PAYMENT = '50'

// 不应该在选择付款方式处显示的付款方式列表
export const PAYMENTS_NOT_DISPLAYED = [
  '50', // 终端赠品
  '60', // 配送费
  '70', // 基药回执
  '80', // 直营收款
  '30', // 退货
]

// 属于赠品分类的分类 ID 列表。该分类下的商品会自动填写赠品收款金额
export const GOODS_CATEGORY_GIFT = [
  '40', // 赠品
]
