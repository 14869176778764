import React from 'react'
import { Tabs, Toast, Modal, Calendar, Badge } from 'antd-mobile'
import ReactTouchEvents from 'react-touch-events'
import NavBar from '../../components/NavBar'
import SimpleTaskItem from '../../components/WorkOrderItem/SimpleTaskItem'
import PaymentInfo from '../../components/PaymentInfo'
import ReturnInfo from '../../components/ReturnInfo/index'
import GoodsDetail from './GoodsDetail'
import { getGoodsCategories } from '../../lib/goodsCategories'
import * as paymentMethods from '../../lib/paymentMethods'
import * as workOrderApi from '../../lib/API/workOrder'
import { ORDER_STATUS, PAY_STATUS, CUSTOMER_TYPES, GOODS_CATEGORY_GIFT, TASK_STATUS, PAYMENTS_NOT_DISPLAYED, DIRECT_SALE_STORE_PAYMENT, BASIC_MEDICINE_RECEIPT_PAYMENT } from '../../lib/constants'
import DebtInfo from '../../components/DebtInfo'
import FooterButtons from "../../components/FooterButtons"
import OrderInfo from "./OrderInfo"
import { formatPrice, moneyAdd, groupBy, getTotal } from "../../lib/utils"
import './style.less'
import { formatDate } from '../../lib/date'
const alert = Modal.alert;
export default class Task extends React.Component {

  constructor(props) {
    super(props);

    this.pkid = props.match.params.id

    this.state = {
      currentTab: 'today',
      detail: {},
      goodsCategories: {},
      paymentMethods: {},
      creditPaymentMethods: {},
      normalPayments: [],
      creditPayments: [],
      editable: false, // 是否可编辑。点击记账后会进入编辑状态
      returnEditable: false, // 是否可以退货
      creditEditable: window.localStorage.getItem('task_status') !== TASK_STATUS.DONE, // 欠款收款的编辑状态。默认是可编辑
      directPaymentApplied: false, // 是否已应用某种直接收款方式。此选项与 editable 共同决定底部记账按钮的显示状态
      remainAmount: 0, // 待还总额
      selectDateVisible: false, // 时间选择器
      returnedInfo: [], // 已经退货货品
      orderInfo: [], // 选中日期订单的退货明细
      dates: {},
      active: '1', // ’1‘、工单详情页，’2‘、选择退货商品页
      selectDate: null, // 选中的日期
      returnGoodsInfo: [], // 要退货货品
      editAddBtnFlag: true, //编辑记账时添加方式按钮状态
    }
  }

  componentDidMount() {
    window.document.title = '工单详情'

    window.scrollTo(0, 0)

    this.loadWorkOrderData()

    // 加载商品分类数据
    getGoodsCategories()
      .then(categories => {
        this.setState({
          goodsCategories: categories,
        })
      })

    // 加载支付方式数据
    paymentMethods.getPaymentMethods()
      .then(methods => {
        this.setState({ paymentMethods: methods })
        // 加载退货明细
        this.getReturnedList()
      })

    // 加载欠款付款方式数据
    paymentMethods.getCreditPaymentMethods()
      .then(methods => {
        this.setState({ creditPaymentMethods: methods })
      })
  }

  componentDidCatch(error, errorInfo) {
    console.error(error)
    Toast.hide()
    Toast.fail(error.message, 2)
  }

  delayDelivery = () => {
    const closeModal = Modal.alert('提示', `确定该客户今日不送货？`, [
      { text: '取消', onPress: () => console.log('cancel') },
      {
        text: '确定', onPress: () => {
          Toast.loading('加载中...', 0)
          workOrderApi.delay(this.pkid)
            .then((resp) => {
              if (resp.isOK) {
                Toast.hide()
                closeModal.close()
                this.props.history.push(`/delivery`)
              } else {
                Toast.hide()
                Toast.fail(resp.errorMessage, 2)
              }
            })
        }
      },
    ])
  }

  // 保存当日收款的付款信息
  savePayments = () => {
    let { normalPayments } = this.state

    // 判空
    if (!normalPayments || !normalPayments.length) {
      return Toast.fail('请选择付款方式', 2, null, false)
    }

    // 检查当前的支付信息，有未填写金额的项目时，将提示错误并禁止提交
    if (normalPayments.find(item => (!item.payMoney || item.payMoney === null || item.payMoney === undefined) && Number(item.payMoney) !== 0)) {
      Toast.fail('请正确填写收款金额', 2, null, false)
      return
    }

    // 除了基药回执都不可以=0
    if (normalPayments.find(item => Number(item.payMoney) === 0 && item.paymentTypePkid !== 6)) {
      Toast.fail('请正确填写收款金额', 2, null, false)
      return
    }

    // // 基药回执和现金可以为负
    // if (normalPayments.find(item => Number(item.payMoney) < 0 && item.paymentTypePkid !== 6 && item.paymentTypePkid !== 1)) {
    //   Toast.fail('请正确填写收款金额', 2, null, false)
    //   return
    // }

    Modal.alert('提示', '是否确认保存收款信息？', [
      { text: '取消', onPress: () => console.log('cancel') },
      {
        text: '确定', onPress: () => {

          Toast.loading('加载中...', 0)
          workOrderApi.keepNormal({
            normalPayments: this.state.normalPayments,
            pkid: this.pkid,
          }).then((resp) => {
            Toast.hide()
            if (!resp.isOK) {
              Toast.fail(resp.errorMessage, 2)
              return
            }

            //  保存退货信息
            this.saveReturnInfo('save')

            Toast.success('保存记账成功', 2)
            this.setState({
              editable: false,
              returnEditable: false,
            })
            // 重新拉取详情及欠款数据
            this.loadWorkOrderData()

          }).catch(error => {
            Toast.hide()
            Toast.fail(error.message, 2, null, false)
          })
        }
      },
    ])
  }

  saveCreditPayments = () => {
    const { creditPayments } = this.state

    if (!creditPayments.length) {
      Toast.fail('请填写欠款付款信息', 2, null, false)
      return
    }

    // 检查当前的支付信息，有未填写金额的项目时，将提示错误并禁止提交
    if (creditPayments.find(item => !item.payMoney || Number(item.payMoney) <= 0)) {
      Toast.fail('请正确填写收款金额', 2, null, false)
      return
    }

    Modal.alert('提示', '是否确认保存欠款收款信息？', [
      { text: '取消', onPress: () => console.log('cancel') },
      {
        text: '确定', onPress: () => {
          Toast.loading('加载中...', 0)
          workOrderApi.keepCredit({
            creditPayments: creditPayments,
            workOrderPkid: this.pkid,
          }).then((resp) => {
            Toast.hide()
            if (!resp.isOK) {
              Toast.fail(resp.errorMessage, 2)
              return
            }

            Toast.success('保存欠款收款信息成功', 2)
            this.setState({
              creditEditable: false,
            })

            // 保存成功之后，重新拉取欠款信息数据
            this.loadWorkOrderData('debt')

          }).catch(error => {
            Toast.hide()
            Toast.fail(error.message, 2, null, false)
          })
        }
      },
    ])
  }

  // 获取已经退货列表
  getReturnedList = () => {
    workOrderApi.listReturned({
      orderPkid: this.pkid
    }).then(res => {
      if (res.isOK) {
        const arr = groupBy(res.data.rows || [], function (item) {
          return [item.taskDate];//按照name进行分组
        });
        if (arr) {
          this.setState({
            returnedInfo: arr,
            returnGoodsInfo: arr,
          })
        }
      }
    })
  }

  // 退货保存
  saveReturnInfo = (type) => {
    const { returnGoodsInfo, returnedInfo, detail } = this.state
    let arr = [];

    returnGoodsInfo.map(d => {
      const goods = d.returnGoods.map(m => { return { goodsQty: m.goodsQty, workOrderlinePkid: (m.workOrderlinePkid || m.pkid) } })
      arr = [...arr, ...goods]
    })


    if (!returnedInfo.length && !arr.length) {
      return Toast.fail('请选择要退货的货品', 2, null, false)
    }

    const c = arr.filter(f => f.goodsQty <= 0 || !f.goodsQty)
    if (c.length > 0) {
      return Toast.fail('退货数量需>0', 2, null, false)
    }


    // 保存退货
    Toast.loading('退货中...', 0)
    workOrderApi.returnSave({
      returns: arr,
      workOrderPkid: this.pkid
    }).then(resp => {
      Toast.hide()
      if (resp.isOK) {
        if (!type) {
          Toast.success('退货保存成功', 2)
        }
        // 获取退货列表
        this.getReturnedList()

      } else {
        Toast.fail(resp.errorMessage, 2)
      }
    })

  }

  // 取消记账。为了避免临时添加的数据与原数据混淆，这里需要重新加载整个订单的数据
  cancelKeepPayments = () => {
    this.setState({ editable: false, directPaymentApplied: false })
    this.loadWorkOrderData()
  }

  // 从服务器加载配送单的状态信息
  loadWorkOrderData = (type) => {
    Toast.loading('加载中...', 0)
    workOrderApi.getTaskDetail(this.pkid)
      .then(resp => {
        Toast.hide()
        if (resp.isOK) {
          // 修改接口返回的付款信息到页面支持的格式
          const detail = resp.data.row

          this.setState({
            detail,
            remainAmount: moneyAdd(...(detail.creditStats || []).map(item => item.creditMoneyRemain)),
          })
          if (type !== 'debt') {
            this.setState({
              normalPayments: detail.normalPaymentDetails.map(item => {
                return {
                  payMoney: item.payMoney,
                  paymentTypePkid: item.paymentTypePkid,
                  paymentTypeFeature: item.paymentTypeFeature,
                }
              }),
            })
          }

        } else {
          Toast.fail(resp.errorMessage, 2)
        }
      })
  }

  onDoDelivery = () => {
    const workOrder = this.state.detail

    const closeModal = Modal.alert('提示', `是否确认开始前往 ${workOrder.customerAddress} 送货？`, [
      { text: '取消', onPress: () => console.log('cancel') },
      {
        text: '确定', onPress: () => {
          Toast.loading('加载中...', 0)
          workOrderApi.startDelivery(workOrder.pkid)
            .then((resp) => {
              if (resp.isOK) {
                Toast.hide()
                closeModal.close()
                Toast.success('已开始配送', 2)
                workOrder.orderStatus = ORDER_STATUS.PROCESSING
                this.setState({
                  detail: workOrder,
                })

              } else {
                Toast.hide()
                Toast.fail(resp.errorMessage, 2)
              }
            })
        }
      },
    ])
  }

  // 点击 "编辑记账" 按钮进行编辑的事件

  onKeepEditClick = () => {
    const { normalPayments } = this.state
    const payments = normalPayments.map(m => {
      if (PAYMENTS_NOT_DISPLAYED.includes(m.paymentTypeFeature)) {
        return { ...m, disabled: true }
      } else {
        return { ...m }
      }
    })
    const length = normalPayments.filter(f => f.paymentTypeFeature === BASIC_MEDICINE_RECEIPT_PAYMENT || f.paymentTypeFeature === DIRECT_SALE_STORE_PAYMENT).length
    if (length > 0) {
      this.setState({
        editAddBtnFlag: false
      })
    }

    this.setState({
      editable: true,
      normalPayments: payments
    })
  }

  // 点击 "记账" 按钮的事件
  onKeepClick = () => {
    const { detail, returnGoodsInfo } = this.state
    Toast.loading('请稍等...', 0.35, () => {
      // 判断是否有退货，存在退货自动填充退货项
      let obj = null
      let total = 0;
      if (returnGoodsInfo && returnGoodsInfo.length) {
        (returnGoodsInfo).map(d => {
          total = total + getTotal(d.returnGoods, 'goodsPriceSubtotal')
        })
        obj = {
          payMoney: formatPrice(total),
          paymentTypePkid: 8,
          disabled: true,
        }
      }

      // 判断当前店铺类型，如果是直营店，就自动填充 "直营收款"，且金额等于总金额，且不可编辑
      // 如果是基药，就自动填充 "基药回执"
      if (detail.customerType === CUSTOMER_TYPES.DIRECT_SALE_STORE || detail.customerType === CUSTOMER_TYPES.BASIC_MEDICINE) {
        const totalAmount = detail.orderlines.reduce((prev, current) => prev + Math.round((current.goodsPriceSubtotal || 0) * 1000), 0) || 0

        const payMoneyTemp = detail.customerType === CUSTOMER_TYPES.BASIC_MEDICINE ? totalAmount / 1000 - total : totalAmount / 1000
        // 从付款方式中找到类型为直营收款的项目的 pkid
        const paymentPkid = detail.customerType === CUSTOMER_TYPES.DIRECT_SALE_STORE ? paymentMethods.getPkidOfDirectSalePayment() : paymentMethods.getPkidOfBasicMedicineReceiptPayment()
        if (paymentPkid) {
          let arr = [{
            payMoney: payMoneyTemp,
            paymentTypePkid: paymentPkid,
          }]

          if (obj) {
            arr.push(obj)
          }

          this.setState({
            normalPayments: arr,
            editable: false,
            directPaymentApplied: true, // 设置直营收款状态，用于修改底部操作按钮
          })

          // 基药可以退货
          if (detail.customerType === CUSTOMER_TYPES.BASIC_MEDICINE) {
            this.setState({
              returnEditable: true
            })
          }

          return
        }

      } else {
        // 其他情况，判断商品内容中是否包含赠品
        const goods = detail.orderlines || []
        const gifts = []
        goods.forEach(item => {
          if (GOODS_CATEGORY_GIFT.includes(item.goodsCategory)) {
            gifts.push(item)
          }
        })
        const normalPayments = []
        if (gifts.length) {
          const paymentPkid = paymentMethods.getPkidOfGiftPayments()
          if (paymentPkid) {

            const money = gifts.reduce((prev, current) => prev + Math.round((current.goodsPriceSubtotal || 0) * 1000), 0) / 1000
            if (Number(money)) {
              normalPayments.push({
                payMoney: money,
                paymentTypePkid: paymentPkid,
              })
            }
          }
        }
        if (obj) {
          normalPayments.push(obj)
        }
        this.setState({
          normalPayments,
          editable: true,
        })
      }

      this.setState({ editable: true })

    })
  }

  // 确认送达
  onDoReceive = () => {
    Modal.alert('是否确认已送达以下地址', this.state.detail.customerAddress, [
      { text: '取消', onPress: () => console.log('cancel') },
      {
        text: '确定', onPress: () => {
          Toast.loading('加载中...', 0)
          workOrderApi.end(this.pkid)
            .then(resp => {
              if (resp.isOK) {
                Toast.hide()
                Toast.success('配送完成', 2, () => {
                  this.props.history.goBack()
                })

              } else {
                Toast.hide()
                Toast.fail(resp.errorMessage, 2)
              }
            })
        }
      },
    ])
  }

  // 渲染底部的操作按钮
  renderFooterButtons = () => {

    const { normalPayments, returnedInfo, returnEditable, active, currentTab, detail, editable, creditEditable, directPaymentApplied, remainAmount } = this.state
    // 今日不配送状态，配送日期!==当前日期
    const flag = detail.orderStatus === ORDER_STATUS.TERMINATED && (formatDate(new Date(detail.taskDate)) !== formatDate(new Date()))
    const task_status = window.localStorage.getItem('task_status') === TASK_STATUS.DONE;
    const open_flag = window.localStorage.getItem('APP_ORDER') === '1';
    // 当前标签页为 "退货" 时，底部仅显示保存按钮
    if (currentTab === 'return' && active === '1') {
      // 已记过账不显示保存按钮
      return (
        (editable || returnEditable) && (detail.orderStatus !== ORDER_STATUS.DONE) &&
        <FooterButtons>
          <ReactTouchEvents touchClass="active" onTap={() => this.props.history.goBack()}>
            <button className="footer-btn">返回</button>
          </ReactTouchEvents>
          <ReactTouchEvents touchClass="active" onTap={() => this.saveReturnInfo()}>
            <button className="footer-btn btn-primary">保存</button>
          </ReactTouchEvents>
        </FooterButtons>
      )
    }

    // 当前标签页为 "欠款收款" 时，底部仅显示保存和返回按钮
    if (currentTab === 'debt') {
      return (
        (creditEditable && remainAmount > 0 && !detail.creditPaymentDetails.length) && (detail.orderStatus !== ORDER_STATUS.DONE || flag) &&
        <FooterButtons>
          <ReactTouchEvents touchClass="active" onTap={() => this.props.history.goBack()}>
            <button className="footer-btn">返回</button>
          </ReactTouchEvents>
          <ReactTouchEvents touchClass="active" onTap={this.saveCreditPayments}>
            <button className="footer-btn btn-primary">保存</button>
          </ReactTouchEvents>
        </FooterButtons>
      )
    }

    // 订单状态为待配送时，只显示 "今日不送货"
    if (detail.orderStatus === ORDER_STATUS.PENDING) {
      return <div className="footer-buttons">
        <ReactTouchEvents touchClass="active" onTap={this.delayDelivery}>
          <button className="footer-btn">今日不送货</button>
        </ReactTouchEvents>
        <ReactTouchEvents touchClass="active" onTap={this.onDoDelivery}>
          <button className="footer-btn btn-primary">前往送货</button>
        </ReactTouchEvents>
      </div>
    } else if (detail.orderStatus === ORDER_STATUS.PROCESSING && detail.payStatus === PAY_STATUS.NONE) {
      // 订单状态为配送中，且支付状态为待记账时，显示记账按钮
      if (editable || directPaymentApplied) {
        return <FooterButtons>
          <ReactTouchEvents touchClass="active" onTap={this.cancelKeepPayments}>
            <button className="footer-btn">取消</button>
          </ReactTouchEvents>
          <ReactTouchEvents touchClass="active" onTap={this.savePayments}>
            <button className="footer-btn btn-primary">保存</button>
          </ReactTouchEvents>
        </FooterButtons>
      } else {
        return <FooterButtons>
          {
            returnedInfo && returnedInfo.length ? '' : <ReactTouchEvents touchClass="active" onTap={this.delayDelivery}>
              <button className="footer-btn">今日不送货</button>
            </ReactTouchEvents>
          }
          <ReactTouchEvents touchClass="active" onTap={this.onKeepClick}>
            <button className="footer-btn btn-primary">记账</button>
          </ReactTouchEvents>
        </FooterButtons>
      }

    } else if (detail.orderStatus === ORDER_STATUS.PROCESSING && (detail.payStatus === PAY_STATUS.DONE || detail.payStatus === PAY_STATUS.HAS_DEBT)) {
      // 订单状态为配送中，且支付状态为记账完成时，显示返回和确认送达按钮
      return <FooterButtons>
        <ReactTouchEvents touchClass="active" onTap={() => this.props.history.goBack()}>
          <button className="footer-btn">返回</button>
        </ReactTouchEvents>
        <ReactTouchEvents touchClass="active" onTap={this.onDoReceive}>
          <button className="footer-btn btn-success">确认送达</button>
        </ReactTouchEvents>
      </FooterButtons>
    } else if (flag) {
      if ((editable || directPaymentApplied) && detail.payStatus === PAY_STATUS.NONE) {
        // 可编辑，未记账，显示保存、返回按钮
        return <FooterButtons>
          <ReactTouchEvents touchClass="active" onTap={this.cancelKeepPayments}>
            <button className="footer-btn">取消</button>
          </ReactTouchEvents>
          <ReactTouchEvents touchClass="active" onTap={this.savePayments}>
            <button className="footer-btn btn-primary">保存</button>
          </ReactTouchEvents>
        </FooterButtons>
      } else if ((!editable || directPaymentApplied) && detail.payStatus === PAY_STATUS.NONE && !task_status) {
        // 不可编辑，未记账，显示记账按钮
        return <FooterButtons>
          <ReactTouchEvents touchClass="active" onTap={this.onKeepClick}>
            <button className="footer-btn btn-primary">记账</button>
          </ReactTouchEvents>
        </FooterButtons>
      } else {
        return <FooterButtons>
          <ReactTouchEvents touchClass="active" onTap={() => this.props.history.goBack()}>
            <button className="footer-btn">返回</button>
          </ReactTouchEvents>
        </FooterButtons>
      }
    } else {
      const length = normalPayments.filter(f => f.paymentTypeFeature === DIRECT_SALE_STORE_PAYMENT).length
      // 直营收款不许改
      // 已处理状态+未结束配送单+允许开启功能 =允许修改记账
      if ((detail.orderStatus === ORDER_STATUS.DONE && !task_status && open_flag) && !editable && !length) {
        return <FooterButtons>
          <ReactTouchEvents touchClass="active" onTap={this.onKeepEditClick}>
            <button className="footer-btn btn-primary">修改记账</button>
          </ReactTouchEvents>
        </FooterButtons>
      } else if (editable && !length) {
        return <FooterButtons>
          <ReactTouchEvents touchClass="active" onTap={this.savePayments}>
            <button className="footer-btn btn-primary">保存</button>
          </ReactTouchEvents>
        </FooterButtons>
      } else {
        // 其他状态，显示返回按钮
        return <FooterButtons>
          <ReactTouchEvents touchClass="active" onTap={() => this.props.history.goBack()}>
            <button className="footer-btn">返回</button>
          </ReactTouchEvents>
        </FooterButtons>
      }

    }

  }

  // 日期禁用
  // getDateExtra = date => this.state.dates[+date];

  // 日历控件的控制
  showDateCalendar = (flag) => {
    // 获取存在订单的日期列表
    this.setState({
      selectDateVisible: flag
    })
  }

  // 确认日期选择
  onConfirm = (date) => {
    const { returnGoodsInfo } = this.state
    const arr = returnGoodsInfo.filter(d => d.date === formatDate(date))
    if (arr.length) {
      alert('提示', '是否重新选择当前日期的退货货品？', [
        { text: '取消', onPress: () => this.showDateCalendar(false) },
        {
          text: '确定', onPress: () => {
            this.getReturninfo(date, arr[0].returnGoods)
          }
        },
      ])
    } else {
      this.getReturninfo(date)
    }
  }

  getReturninfo = (date, selectRows) => {
    const { detail } = this.state
    document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
    this.showDateCalendar(false)
    workOrderApi.listForReturn({
      customerId: detail.customerId,
      translineId: detail.translineId,
      taskDate: date
    })
      .then(resp => {
        if (resp.isOK) {
          const _rows = resp.data.rows || []
          let rows = []
          if (selectRows) {
            const pkids = selectRows.map(d => d.workOrderlinePkid || d.pkid)
            _rows.map(m => {
              if (pkids.includes(m.pkid)) {
                rows.push({ ...m, disabled: true })
              } else {
                rows.push({ ...m })
              }
            })
          } else {
            rows = _rows
          }

          this.setState({
            orderInfo: rows,
            active: '2',
            selectDate: date
          })
        } else {
          Toast.fail(resp.errorMessage, 2)
        }
      })
  }

  // 确认选择退货商品
  confirmGoods = (info) => {
    const { returnGoodsInfo } = this.state
    let rows = []
    // 合并退货数据
    const index = returnGoodsInfo.findIndex(f => f.date === info.date)
    if (index > -1) {
      returnGoodsInfo.map(m => {
        if (m.date === info.date) {
          const arr = [...m.returnGoods, ...info.returnGoods]
          rows.push({ ...m, returnGoods: arr })
        } else {
          rows.push({ ...m })
        }
      })
    } else {
      rows = [...returnGoodsInfo, info]
    }

    this.setState({
      active: '1',
      returnGoodsInfo: rows
    })
  }

  // 删除退货货品或修改数量
  onUpdateGood = (date, pkid, qty) => {
    const { returnGoodsInfo } = this.state
    let arr = [];
    returnGoodsInfo.map(d => {
      if (d.date === date) {
        let goods = [];
        // 修改数量
        if (qty || qty === 0 || qty === '') {
          goods = (d.returnGoods || []).map(m => {
            if ((m.workOrderlinePkid || m.pkid) === pkid) {
              if (qty < 0) {
                Toast.info('退货数量需>0', 2)
              }
              return { ...m, goodsQty: qty, goodsPriceSubtotal: m.goodsPrice * qty }
            } else {
              return { ...m }
            }
          })
        } else {
          // 删除退货货品
          goods = (d.returnGoods || []).filter(f => (f.workOrderlinePkid || f.pkid) !== pkid)
        }
        if (goods.length) {
          arr.push(
            {
              date: date,
              returnGoods: goods
            }
          )
        }
      } else {
        arr.push(d)
      }
    })
    this.setState({
      returnGoodsInfo: arr
    })
  }

  render() {
    const { editAddBtnFlag, returnEditable, returnedInfo, returnGoodsInfo, selectDate, active, orderInfo, selectDateVisible, detail, goodsCategories, paymentMethods, creditPaymentMethods, normalPayments, creditPayments, editable, creditEditable, remainAmount } = this.state
    const now = new Date();
    // 累加欠款金额信息，并在欠款大于 0 时显示在标签页上
    const creditTotal = moneyAdd(...(detail.creditStats || []).map(item => item.creditMoneyRemain))
    const flag = (detail.orderStatus === ORDER_STATUS.TERMINATED && (formatDate(new Date(detail.taskDate)) !== formatDate(new Date()))) || detail.orderStatus !== ORDER_STATUS.DONE

    const tabs = [
      {
        key: 'today',
        title: '当日收款'
      },
      {
        key: 'debt',
        title: creditTotal > 0
          ? <Badge text={formatPrice(creditTotal)}>欠款收款</Badge>
          : '欠款收款'
      },
      {
        key: 'return',
        title: '退货'
      },
    ]

    return (
      <div className="page-task">
        {
          active === '1' ?
            <>
              <NavBar title="工单详情" back />
              {/* 工单信息 */}
              <div className="task-info">
                <SimpleTaskItem workOrder={detail} />
              </div>

              {/* 收款 */}
              <div className="task-details">
                <Tabs tabs={tabs}
                  page={this.state.currentTab}
                  tabBarBackgroundColor="transparent"
                  swipeable={false}
                  onTabClick={tabData => {
                    if (JSON.stringify(returnGoodsInfo) !== JSON.stringify(returnedInfo)) {
                      if (detail.orderStatus === ORDER_STATUS.DONE) {
                        this.setState({ currentTab: tabData.key })
                        return
                      }

                      this.setState({ currentTab: this.state.currentTab })
                      Toast.info('请先保存退货信息', 2)
                    } else {
                      this.setState({ currentTab: tabData.key })
                    }
                  }}
                >
                  <div key="today" className="task-today">
                    {/* 付款信息 */}
                    <PaymentInfo
                      editAddBtnFlag={editAddBtnFlag}
                      totalGoodsPrice={detail.totalGoodsPrice}
                      title="付款信息"
                      placeholder="暂无付款信息"
                      paymentMethods={paymentMethods}
                      payments={normalPayments}
                      editable={editable}
                      returnGoodsInfo={returnGoodsInfo}
                      onPaymentsChange={(val) => this.setState({ normalPayments: val })}
                    />

                    {/* 货品信息 */}
                    <GoodsDetail
                      orderLines={detail.orderlines || []}
                      deliveryFee={detail.deliveryFee || 0}
                      goodsCategories={goodsCategories}
                    />
                  </div>

                  <div key="debt" className="debt-info">
                    {/* 付款信息 */}
                    <PaymentInfo
                      editAddBtnFlag={true}
                      creditPaymentDetails={detail.creditPaymentDetails}
                      title="欠款付款信息"
                      placeholder="暂无欠款信息"
                      paymentMethods={creditPaymentMethods}
                      payments={creditPayments}
                      editable={creditEditable && detail.creditStats && detail.creditStats.length && remainAmount > 0 && !detail.creditPaymentDetails.length && flag}
                      onPaymentsChange={(val) => this.setState({ creditPayments: val })}
                    />
                    {/* 欠款信息 */}
                    <DebtInfo creditStats={detail.creditStats} />
                  </div>

                  <div key="return" className="return-info">
                    {/* 退货 */}
                    {/* 待记账状态可以退货 */}
                    <ReturnInfo
                      returnEditable={returnEditable}
                      title="退货信息"
                      placeholder="暂无退货信息"
                      editable={editable}
                      returnGoodsInfo={returnGoodsInfo}
                      showDateCalendar={this.showDateCalendar}
                      onUpdateGood={this.onUpdateGood}
                    />
                  </div>
                </Tabs>
                {/* 选择退货时间 */}
                <Calendar
                  type='one'
                  visible={selectDateVisible}
                  onCancel={() => this.showDateCalendar(false)}
                  onConfirm={this.onConfirm}
                  // getDateExtra={this.getDateExtra}
                  defaultDate={now}
                  minDate={new Date(+now - 1000 * 60 * 60 * 24 * 30)}
                  maxDate={new Date()}
                />
              </div>
              {/* 底部留白 */}
              <div style={{ height: '70px' }}></div>

              {/* 底部操作按钮*/}
              { this.renderFooterButtons()}
            </> :
            <>
              <NavBar title="选择货品" back onBack={() => this.setState({ active: '1' })} />
              {/* 可退货明细 */}
              <OrderInfo
                orderLines={orderInfo}
                selectDate={selectDate}
                goodsCategories={goodsCategories}
                confirmGoods={this.confirmGoods}
              />
            </>
        }

      </div>
    )
  }
}
