import http from '../http'

// 前往送货
export function startDelivery(pkid) {
  return http.post('/work-order/start', {
    pkid,
  })
}

// 今日不送货
export function delay(pkid) {
  return http.post('/work-order/delay', {
    pkid,
  })
}

// 获取配送单详情
export function getTaskDetail(pkid) {
  return http.get(`/work-order/${pkid}`)
}

// 普通记账
export function keepNormal(orderInfo) {
  return http.post('/work-order/keep/normal', orderInfo)
}

// 欠款记账
export function keepCredit(orderInfo) {
  return http.post('/work-order/keep/credit', orderInfo)
}

// 确认送达
export function end(pkid) {
  return http.post('/work-order/end', {
    pkid,
  })
}

// 退货-存在订单的日期列表
export function listDateForReturn(params) {
  return http.get('/work-order/list-date-for-return', { params })
}
// 退货-根据日期查询可退货明细
export function listForReturn(params) {
  return http.get('/work-order/list-for-return', { params })
}
// 退货
export function returnSave(params) {
  return http.post('/work-order/keep/return', params)
}
// 退货-退货明细
export function listReturned(params) {
  return http.get('/work-order/list-returned', { params })
}