import React from 'react'
import { Card } from 'antd-mobile'
import { formatPrice } from "../../lib/utils"


export default function GoodsDetail({ orderLines, goodsCategories, deliveryFee = 0 }) {

  // 根据商品分类将所有商品分组
  const groups = {}

  // 是否冷藏
  let isRefrigeration = 0

  orderLines.forEach(item => {
    const category = item.goodsCategory

    if (!groups[category]) {
      groups[category] = {
        name: goodsCategories[category] ? goodsCategories[category].codeValue : '其他',
        amountSubtotal: 0, // 小计金额，以分为单位
        qtySubtotal: 0,
        goods: []
      }
    }

    groups[category].goods.push(item)
    groups[category].amountSubtotal += Math.round(item.goodsPriceSubtotal * 1000)
    groups[category].qtySubtotal += item.goodsQty

    // 判断是否冷藏。只要配送单中任一商品名称以 L 开头，即判定配送单为冷藏
    isRefrigeration = (isRefrigeration | (item.goodsName.startsWith('L'))) & 1
  })

  // 所有商品的总价，以分为单位
  const totalPrice = orderLines.reduce((prev, current) => prev + Math.round((current.goodsPriceSubtotal || 0) * 1000), 0)
  const totalPriceWithDeliveryFee = totalPrice + Math.round((deliveryFee || 0) * 1000)

  return (
    <Card className="goods-detail" style={{ marginTop: '12px' }}>
      <Card.Header
        title={<span>货品信息 {(isRefrigeration ? <span style={{ fontSize: '12px', color: '#FB782F' }}>(*冷藏)</span> : null)}</span>}
        extra={<div>
          {
            deliveryFee > 0 && [
              <div key={1} className="card-extra" style={{ marginBottom: '4px' }}>总金额：¥{formatPrice((totalPrice || 0) / 1000)}</div>,
              <div key={2} className="card-extra" style={{ marginBottom: '4px' }}>配送费：¥{formatPrice(deliveryFee || 0)}</div>
            ]
          }
          <div className="card-extra">总计：¥{formatPrice((totalPriceWithDeliveryFee || 0) / 1000)}</div>
        </div>}
      />
      <Card.Body>
        {!orderLines.length ?
          '' :
          Object.keys(groups).map(cate => {
            return (
              <section key={cate}>
                <div className="section-title">{groups[cate].name}</div>
                <table>
                  <tbody>
                    {
                      groups[cate].goods.map((item, index) => {
                        return [
                          <tr key={'name' + index}>
                            <td className="goods-name" colSpan="3">
                              {item.goodsName}
                              {item.lotNo ? ` (${item.lotNo})` : ''}
                            </td>
                          </tr>,
                          <tr key={index}>
                            <td>{'￥' + (item.goodsPrice || '-')}</td>
                            <td align="right" style={{ minWidth: '40px' }}>{(item.goodsQty || '-') + '' + item.goodsUnit}</td>
                            <td align="right">{'￥' + formatPrice(item.goodsPriceSubtotal)}</td>
                          </tr>
                        ]
                      })
                    }
                    <tr className="subtotal">
                      <td>合计</td>
                      <td align="right">{groups[cate].qtySubtotal}</td>
                      <td align="right">{'￥' + formatPrice((groups[cate].amountSubtotal || 0) / 1000)}</td>
                    </tr>
                  </tbody>
                </table>
              </section>
            )
          })
        }

      </Card.Body>
    </Card>
  )
}
