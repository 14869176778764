import React from 'react'
import { Modal, Tabs, Toast } from 'antd-mobile'
import ReactTouchEvents from 'react-touch-events'
import NavBar from '../../components/NavBar'
import SummaryCard from './SummaryCard'
import TabBar from '../../components/TabBar'
import SummaryDialog from './SummaryDialog'
import * as deliveryApi from '../../lib/API/delivery'
import * as courierApi from '../../lib/API/courier'
import * as taskApi from '../../lib/API/task'
import { ORDER_STATUS, TASK_STATUS } from '../../lib/constants'
import TaskList from './TaskList'
import SearchBox from "../../components/SearchBox"
import './style.less'
import { formatDate } from "../../lib/date";
import { makePhoneCall, normalizePhoneNumber } from '../../lib/utils'


export default class Delivery extends React.Component {

  constructor(props) {
    super(props)

    const searchParams = new URLSearchParams(props.location.search || '')

    this.state = {
      taskStatus: '',
      pkid: 0,
      initDataTab: searchParams.get('tab') || 'pending',
      summary: {},
      isShowSummaryDialog: false,
      refreshIndicator: 0,  // 刷新标识。以此作为组件的 key，用于强制列表数据刷新
      keywords: searchParams.get('keywords') || '', // 搜索关键词
      statistics: [],
      isShowEndDelivery: false, // 是否显示"结束今日配送"按钮
      payTypes: []
    }
  }

  async componentDidMount() {
    this.getAppOrderOpenFlag()
    window.document.title = '当前任务'
    // 获取跳转详情时列表位置
    const scrollY = window.localStorage.getItem('scrollY') || 0
    if (scrollY) {
      console.log('scrollY', scrollY)
      document.documentElement.scrollTop = scrollY
    }
    await this.getSummary()

    this.getStatistics()

    // 仅当配送总单的状态是待配送时弹窗提示
    if (this.state.taskStatus === TASK_STATUS.PENDING) {
      Toast.loading('加载中...', 0)

      // 获取配送员信息，并强框确认开始配送
      const resp = await courierApi.my()
      Toast.hide()
      if (!resp.isOK) {
        Toast.fail(resp.errorMessage, 2)
        return
      }

      const userInfo = resp.data.row
      const closeModal = Modal.alert('欢迎登录龙腾行动', <div>
        <span>账号：{userInfo.userLogin}</span><br />
        <span>司机姓名：{userInfo.userName}</span><br />
        <span>手机号：{userInfo.userMobile}</span><br />
        <span>运输路线：{userInfo.workTranslineName}</span><br />
        <span>配送日期：{userInfo.taskDate ? formatDate(new Date(userInfo.taskDate)) : '-'}</span><br />
        <span>开票员姓名：{userInfo.invoiceUser ? userInfo.invoiceUser.userName : '-'}</span><br />
        <span>开票员手机号：{userInfo.invoiceUser ?
          <ReactTouchEvents touchClass="active" onTap={makePhoneCall.bind(this, normalizePhoneNumber(userInfo.invoiceUser.userMobile))}>
            <span style={{ marginRight: 20 }} >{userInfo.invoiceUser.userMobile}</span>
          </ReactTouchEvents> : '-'}</span>
      </div>, [
        {
          text: '确定', onPress: () => {
            Toast.loading('加载中...', 0)
            taskApi.start(this.state.pkid)
              .then((resp) => {
                if (resp.isOK) {
                  Toast.hide()
                  closeModal.close()

                } else {
                  Toast.hide()
                  Toast.fail(resp.errorMessage, 2)
                }
              })
          }
        },
      ])
    }
    // 已完成任务状态不显示结束今日配送按钮
    if (this.state.taskStatus === TASK_STATUS.DONE) {
      this.setState({
        isShowEndDelivery: false,
      })
    } else {
      this.setState({
        isShowEndDelivery: true,
      })
    }
  }

  async getSummary() {
    Toast.loading('加载中...', 0)
    const resp = await deliveryApi.getSummary()
      .catch(err => console.error(err))
    if (resp.isOK) {
      Toast.hide()
      const data = resp.data.row
      window.localStorage.setItem('task_status', data.taskStatus)
      this.setState({
        pkid: data.pkid,
        taskStatus: data.taskStatus,
        summary: {
          taskDate: data.taskDate,
          customerCountTotal: data.customerCountTotal,
          customerCountWait: data.customerCountWait,
          customerCountDelivered: data.customerCountDelivered,
          moneyProcessed: data.moneyProcessed,
          customerCountCredit: data.customerCountCredit,
          customerCountReturn: data.customerCountReturn,
          customerCountDelay: data.customerCountDelay,
          moneyTotal: data.moneyTotal,
          moneyWait: data.moneyWait,
          moneyReceived: data.moneyReceived,
          moneyCredit: data.moneyCredit,
          moneyReturn: data.moneyReturn,
          moneyDelay: data.moneyDelay,
          startTime: data.startTime,
        },
      })

    } else {
      Toast.hide()
      Toast.fail(resp.errorMessage, 2)
    }
  }

  getAppOrderOpenFlag = () => {
    // 获取是否可以修改记账标志, 返回1的时候可以编辑，其他情况不可用编辑
    taskApi.getConfig({ configKey: 'EDIT_FLAG', configType: 'APP_ORDER' }).then(resp => {
      if (resp.isOK) {
        if (resp.data) {
          window.localStorage.setItem('APP_ORDER', resp.data.row)
        }
      }
    })
  }

  getStatistics = async () => {
    const resp = await deliveryApi.getTaskStatistics(this.state.pkid)
    if (resp.isOK) {
      this.setState({
        statistics: resp.data.row ? resp.data.row.workTaskCustomerStatisticsExtStats : [],
        payTypes: resp.data.row ? resp.data.row.workPaymentDetailExtStats.filter(f => f.paymentTargetCode !== '20') : [],
      })
    } else {
      Toast.hide()
      Toast.fail(resp.errorMessage, 2)
    }
  }

  onTabChange = (tab) => {
    if (tab === 'mine') {
      this.props.history.push('/mine')
    }
  }

  // 点击结束今日配送按钮，更新当前 summary 数据，并显示概览弹框
  endDelivery = async () => {
    await this.getSummary()
    this.setState({
      isShowSummaryDialog: true,
    })
  }

  // 确认结束今日配送
  doEndDelivery = () => {
    // 判断当前有未完成的任务时不允许结束配送
    if (this.state.summary.customerCountWait > 0) {
      Toast.info('请确认所有工单已经完成配送', 3, null, false)
      return
    }

    Toast.loading('加载中...', 0)
    taskApi.end(this.state.pkid)
      .then(resp => {
        Toast.hide()
        if (resp.isOK) {
          Toast.success('已结束今日配送', 2, () => {
            this.setState({
              isShowSummaryDialog: false,
              isShowEndDelivery: false,
            })
            this.onDataShouldRefresh()
          })

        } else {
          Toast.fail(resp.errorMessage, 2)
        }
      })
  }

  // 刷新页面全部数据
  onDataShouldRefresh = () => {
    const { refreshIndicator } = this.state
    this.getSummary()
    this.getStatistics()
    this.setState({
      refreshIndicator: refreshIndicator + 1,
    })
  }

  onDataTabChange = (tabData) => {
    this.replaceRouteStates({ tab: tabData.key })
  }

  goToOrderMap = () => {
    this.props.history.push('/order-map/' + this.state.pkid)
  }

  componentDidCatch(error, errorInfo) {
    Toast.hide()
    Toast.fail(error.message, 2)
  }

  doSearch = (keywords) => {
    this.setState({ keywords, refreshIndicator: this.state.refreshIndicator + 1 })
    this.replaceRouteStates({ keywords })
  }

  replaceRouteStates = (newStates = {}) => {
    const params = new URLSearchParams(this.props.location.search)
    Object.keys(newStates).forEach(key => params.set(key, newStates[key]))
    this.props.history.replace('/delivery?' + params.toString())
  }

  render() {
    const { summary, statistics, payTypes, pkid, isShowSummaryDialog, refreshIndicator, initDataTab, keywords, isShowEndDelivery } = this.state

    return (
      <div className="page-delivery">
        <NavBar title="当前任务" right={
          <ReactTouchEvents touchClass="active" onTap={this.goToOrderMap}>
            <i className="navbar-icon iconfont icon-map"></i>
          </ReactTouchEvents>
        }
        />

        {/* 任务概览 */}
        <SummaryCard summary={summary} statistics={statistics} payTypes={payTypes} />

        {/* 滚动字幕 */}
        {/*<div className="scrolling-marquee">*/}
        {/*  <marquee>您正在配送客户泊头市康乐药店，金额1000元,大约30分钟后送达</marquee>*/}
        {/*</div>*/}

        {/* 搜索栏 */}
        <SearchBox
          value={keywords}
          onChange={(keywords) => this.setState({ keywords })}
          onSubmit={this.doSearch}
          onClear={this.doSearch.bind(this, '')}
        />

        {/* 数据列表 */}
        <div className="list-area">
          {/* 标签页 */}
          <Tabs tabs={[{ key: 'pending', title: '待处理' }, { key: 'done', title: '已处理' }]}
            initialPage={initDataTab}
            swipeable={false}
            onChange={this.onDataTabChange}
          >
            {/* 待配送 */}
            <div key="pending" className="task-list pending-tasks">
              {
                pkid !== 0 &&
                <TaskList
                  key={refreshIndicator}
                  type={ORDER_STATUS.PENDING}
                  pkid={pkid}
                  keywords={keywords}
                  history={this.props.history}
                  refreshData={this.onDataShouldRefresh}
                />
              }
            </div>

            {/* 已配送 */}
            <div key="done" className="task-list done-tasks">
              {
                pkid !== 0 &&
                <TaskList
                  key={refreshIndicator}
                  type={ORDER_STATUS.DONE}
                  pkid={pkid}
                  keywords={keywords}
                  history={this.props.history}
                  refreshData={this.onDataShouldRefresh}
                />
              }
            </div>
          </Tabs>
        </div>

        {/* 结束今日配送 */}
        {
          isShowEndDelivery &&
          <ReactTouchEvents touchClass="active" onTap={this.endDelivery}>
            <div className="end-delivery" />
          </ReactTouchEvents>
        }

        {/* 结束今日配送的弹窗 */}
        <SummaryDialog
          visible={isShowSummaryDialog}
          summary={summary}
          onClose={() => this.setState({ isShowSummaryDialog: false })}
          onConfirm={this.doEndDelivery}
        />

        {/* 底部留白 */}
        <div style={{ height: '70px' }}></div>

        <TabBar currentTab="delivery" onChange={this.onTabChange} />
      </div>
    )
  }
}
