import ReactTouchEvents from 'react-touch-events'
import { useHistory } from 'react-router-dom'
import './style.less'

export default function NavBar(props) {
  const history = useHistory()

  const onBack = () => {
    // history.goBack()
    typeof props.onBack === 'function' ? props.onBack() : history.goBack()
  }

  return (
    <div className="c-navbar">
      <div className="navbar-left">
        {
          props.back
            ? <ReactTouchEvents touchClass="active" onTap={onBack}><i className="btn-back iconfont icon-arrow-left"></i></ReactTouchEvents>
            : props.left
        }
      </div>
      <div className="navbar-title">{props.title}</div>
      <div className="navbar-right">{props.right}</div>
    </div>
  )
}
