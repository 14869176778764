import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import FastClick from 'fastclick'
import './styles/index.less'
import Router from './routes'
// import reportWebVitals from './reportWebVitals'

if ('addEventListener' in document) {
  document.addEventListener('DOMContentLoaded', function() {
    FastClick.attach(document.body);
  }, false);
}

// 对接 APP 的返回按钮操作
window.goHistoryBack = function () {
  window.history.go(-1)
}

window.localStorage.setItem('location', JSON.stringify({ latitude:'37.741357', longitude:'115.675615', address:"江苏省苏州市相城区城通路77号靠近苏州港口发展大厦" }))

// 对接 APP 获取用户位置的接口
window.getLocationForH5Result = function (location) {
  // location 返回格式如 { latitude:'31.420411', longitude:'120.640498', address:"江苏省苏州市相城区城通路77号靠近苏州港口发展大厦" }
  window.localStorage.setItem('location', JSON.stringify(location))
  return location
}

// 主动获取用户位置并记录。该方法会触发一次 window.getLocationForH5Result 函数
if (window.H5_OBJ_NAME) {
  window.H5_OBJ_NAME.getLocationForH5()
}

ReactDOM.render(
  <Router />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
