import {Card} from 'antd-mobile'
import {formatDate} from '../../lib/date'
import {formatPrice, moneyAdd} from "../../lib/utils"
import './style.less'


export default function DebtInfo({creditStats = []}) {
  // 计算总金额
  const subtotalAmount = moneyAdd(...creditStats.map(item => item.creditMoney))
  // 计算未还金额
  const remainAmount = moneyAdd(...creditStats.map(item => item.creditMoneyRemain))

  return (
    <Card style={{marginTop: '12px'}} className="c-debt-info">
      <Card.Header
        title="欠款信息"
        extra={
          subtotalAmount === remainAmount
            ? <span className="card-extra">总计：￥{ formatPrice(subtotalAmount) }</span>
            : <span className="card-extra">
                总计：￥<s className="debt-original">{ formatPrice(subtotalAmount) }</s>
                <span className="debt-remain">￥{ formatPrice(remainAmount) }</span>
              </span>
        }
      />
      <Card.Body>
        {
          creditStats.map((item, index) => {
            return <div className="debt-item" key={ index }>
              <span className="debt-date">{ item.creditDate ? formatDate(new Date(item.creditDate)) : '-' }</span>
              {
                /* 当 creditMoneyReturn 不为空且大于 0 时，表示有回款，应当划去原价格并显示剩余金额 */
                item.creditMoneyReturn && item.creditMoneyReturn > 0
                  ? <span className="debt-value">
                      欠款金额：
                      <s className="debt-original">￥{ formatPrice(item.creditMoney) }</s>
                      <span className="debt-remain">￥{ formatPrice(item.creditMoneyRemain) }</span>
                    </span>
                  : <span className="debt-value">欠款金额：￥{ formatPrice(item.creditMoney) }</span>
              }

            </div>
          })
        }
      </Card.Body>
    </Card>
  )
}
