import {Toast} from "antd-mobile"

/**
 * 获取当前位置
 *
 * @returns {Promise<null|{latitude,longitude,address}>}
 */
export async function getCurrentLocation() {
  let location
  try {
    const lo = window.localStorage.getItem('location') || ''
    location = JSON.parse(lo)

  } catch (err) {
    Toast.fail('暂时无法获取位置信息，请稍后再试')
    return null
  }

  return location
}
