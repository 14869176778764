import {TabBar} from 'antd-mobile'
import './style.less'

export default function AppTabBar({currentTab, onChange}) {



  return (
    <div className="c-tab-tabbar">
      <TabBar
        barTintColor="#FFF"
        tintColor="#0884CA"
        unselectedTintColor="#999"
        noRenderContent={true}
        prerenderingSiblingsNumber={0}
      >
        <TabBar.Item
          title="配送"
          key="delivery"
          icon={<TabBarIcon type="delivery" />}
          selectedIcon={<TabBarIcon type="delivery-active" />}
          selected={currentTab === 'delivery'}
          onPress={() => onChange('delivery')}
        ></TabBar.Item>
        <TabBar.Item
          title="我的"
          key="mine"
          icon={<TabBarIcon type="mine" />}
          selectedIcon={<TabBarIcon type="mine-active" />}
          selected={currentTab === 'mine'}
          onPress={() => onChange('mine')}
        ></TabBar.Item>
      </TabBar>
    </div>
  )
}

function TabBarIcon({type}) {
  return (
    <div className={`tabbar-icon ${type}`} />
  )
}
