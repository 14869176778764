import http from '../http'

export function getSummary() {
  return http.get('/work-task/my/current')
}


export function getPendingTasks(pkid, page, pageSize, keywords = '') {
  return http.get('/work-order/wait-for-delivery', {
    params: {
      workTaskPkid: pkid,
      pageNo: page,
      pageSize,
      keyword: keywords,
    }
  })
}

export function getDoneTasks(pkid, page, pageSize, keywords = '') {
  return http.get('/work-order/delivered', {
    params: {
      workTaskPkid: pkid,
      pageNo: page,
      pageSize,
      keyword: keywords,
    }
  })
}

// 按客户类型统计的数据
export function getTaskStatistics(pkid) {
  return http.get(`/work-task-customer-statistics?workTaskPkid=${pkid}`)
}

// 充电桩
export function getChargingStation() {
  return http.get(`/charging-station`)
}
