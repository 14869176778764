import { formatDate } from '../lib/date'
import numeral from 'numeral';

export function classname(obj) {
  return Object.keys(obj)
    .filter(key => !!obj[key])
    .join(' ')
}

export function makePhoneCall(phoneNumber) {
  if (!phoneNumber) return
  console.log('Mark phone call to ', phoneNumber)
  window.location.href = 'tel:' + phoneNumber
}

// 将 +86—18632836182 这种格式的手机号转换为 18632836182 的格式
export function normalizePhoneNumber(phoneNumber) {
  if (!phoneNumber) return ''
  if (phoneNumber.substr(0, 4) === '+86—') {
    return phoneNumber.substr(4)
  }
  return phoneNumber
}

// 将手机号的中间四位替换为星号
export function maskPhoneNumber(phoneNumber) {
  if (!phoneNumber || phoneNumber.length < 8) {
    return phoneNumber
  }

  if (phoneNumber.length > 10) {
    return phoneNumber.substr(0, 3).padEnd(7, '*') + phoneNumber.substr(7)
  }

  return phoneNumber.substr(0, 2).padEnd(6, '*') + phoneNumber.substr(6)
}

function getLength(price) {
  const arr = String(price).split('.')
  let w = 0;
  if (!arr.length || arr.length === 1) {
    w = 0
  } else {
    let l = arr[1].length
    if (l === 1) {
      w = 1
    } else if (l === 2) {
      w = 2
    } else {
      w = 3
    }
  }
  return w;
}

// 格式化金额为保留至小数点后两位的格式，返回 String
export function formatPrice(price) {
  if (price === 0) return '0'
  if (!price) return '-'

  if (typeof price === 'number') {
    const w = getLength(price)
    return price.toFixed(w)

  } else {
    price = parseFloat(price)
    const w = getLength(price)
    if (isNaN(price)) {
      return '-'
    }
    return price.toFixed(w)
  }
}

// 金额累加
export function moneyAdd(...num) {
  const a = num.reduce((prev, current) => {
    return add(prev, (current || 0))
  }, 0)
  return a
}

// 求和
export const getTotal = (objArr, dataIndex) => {
  const total = ((objArr || []).reduce(function (prev, cur) {
    return add((prev || 0), (cur[dataIndex] || 0));
  }, 0));
  return Number(total)
};

export const numValue = num => numeral(num).value();
export const toNum = (n, def = 0) => (!isNaN(n) ? Number(n) : def);
// 加法
export const add = (a, b) =>
  numeral(toNum(a))
    .add(toNum(b))
    .value();

export const groupBy = (array, f) => {
  const groups = {};
  array.forEach(function (o) { //注意这里必须是forEach 大写
    const group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  const arr = Object.keys(groups).map(function (group) {
    return { date: formatDate(new Date(JSON.parse(group)[0])), returnGoods: groups[group] || [] };
  });
  return arr
}

// 判断是安卓设备
export const isAndroid = () => {
  // userAgent 属性是一个只读的字符串，声明了浏览器用于 HTTP 请求的用户代理头的值
  const u = navigator.userAgent;
  // Android终端
  const result = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;

  return result;
}

/**
 * 获取虚拟键盘高度
 * */
export const getKeyboardHeight = () => {
  if (isAndroid()) {
    if (window.H5_OBJ_NAME) {
      return window.H5_OBJ_NAME.getKeyboardHeight();
    }
  }
}
