import React, { useEffect } from 'react'
import { Card, Picker, Modal, Toast } from 'antd-mobile'
import ReactTouchEvents from 'react-touch-events'
import { getPkidOfPaymentsNotDisplayed, getPkidOfGiftPayments } from "../../lib/paymentMethods"
import { formatPrice, getTotal } from "../../lib/utils"
import './style.less'

export default function PaymentInfo({ editAddBtnFlag, creditPaymentDetails, totalGoodsPrice, returnGoodsInfo, title, paymentMethods = [], editable = false, payments = [], onPaymentsChange = null, placeholder = '' }) {

  useEffect(() => {
    if (returnGoodsInfo && returnGoodsInfo.length) {
      let total = 0;
      (returnGoodsInfo).map(d => {
        total = total + getTotal(d.returnGoods, 'goodsPriceSubtotal')
      })
      const arr = payments.filter(f => f.paymentTypePkid === 8)
      // 没有选择退货支付方式，将退货方式自动添加
      if (!arr.length) {
        const arr1 = payments.filter(f => f.paymentTypePkid === 6)
        if (arr1.length) {
          payments = payments.map(d => {
            if (d.paymentTypePkid === 6) {
              // 存在基药回执，自动计算；基药回执金额=应收-退货金额
              return { ...d, payMoney: formatPrice(Number(totalGoodsPrice - total)) }
            } else {
              return { ...d }
            }
          })
        }
        payments.push({
          paymentTypePkid: 8,
          payMoney: formatPrice(total),
          disabled: true,
        })
      } else {
        // 已经选择退货支付方式，将退货金额自动计算
        payments = payments.map(d => {
          if (d.paymentTypePkid === 8) {
            return { ...d, payMoney: formatPrice(total), disabled: true, }
          } else if (d.paymentTypePkid === 6) {
            // 存在基药回执，自动计算；基药回执金额=应收-退货金额
            return { ...d, payMoney: formatPrice(Number(totalGoodsPrice - total)) }
          } else {
            return { ...d }
          }
        })
      }
      paymentChange()
    } else {
      payments = payments.filter(item => item.paymentTypePkid !== 8).map(m => {
        if (m.paymentTypePkid === 6) {
          return { ...m, payMoney: totalGoodsPrice }
        } else {
          return { ...m }
        }
      })
      paymentChange()
    }
  }, [returnGoodsInfo])

  useEffect(() => {
    if (creditPaymentDetails && creditPaymentDetails.length) {
      payments = creditPaymentDetails.map(d => { return { payMoney: d.payMoney, paymentTypePkid: d.paymentTypePkid } })
      paymentChange()
    }
  }, [creditPaymentDetails])

  // 获取赠品对应的 pkid，此项也不允许修改
  const giftPaymentPkid = getPkidOfGiftPayments()
  // 获取不需要显示的付款方式的 Pkid
  const paymentsNotDisplayed = getPkidOfPaymentsNotDisplayed()

  // 整理付款方式为 label: value 格式
  let payment = formatPaymentMethods(paymentMethods)
  // 从付款方式中移除已经选择的付款方式，避免同一种付款方式被多次选择，同时移除不需要显示的项目
  const selectedPaymentMethods = payments.map(item => item.paymentTypePkid)
  payment = payment.filter(item => !selectedPaymentMethods.includes(item.value) && !paymentsNotDisplayed.includes(item.value))

  // 已经添加的付款信息的总计金额，以分为单位
  const subtotalAmount = payments.reduce((prev, current) => prev + Math.round((current.payMoney || 0) * 1000), 0)

  const paymentChange = () => {
    typeof onPaymentsChange === 'function' && onPaymentsChange(payments)
  }

  const addPayment = (arrTypeId) => {
    payments.push({
      payMoney: '',
      paymentTypePkid: arrTypeId[0],
    })
    paymentChange()
  }

  const onAmountChange = (typeId, event) => {
    let value = event.target.value.trim()
    payments.filter(item => item.paymentTypePkid === typeId).forEach(item => item.payMoney = value)
    paymentChange()
  }

  const onKeypress = event => {
    if (event.charCode === 13) {
      event.target.blur()
    }
  }

  const removePayment = (typeId) => {
    Toast.loading('加载中...', 0.35, () => {
      Modal.alert('提示', '确定删除该付款方式？', [
        { text: '取消', onPress: () => console.log('cancel') },
        {
          text: '确定', onPress: () => {
            payments = payments.filter(item => item.paymentTypePkid !== typeId)
            paymentChange()
          }
        }
      ])
    })
  }

  return (
    <Card className="c-payment-info">
      <Card.Header
        title={title}
        extra={<span className="card-extra">总计：￥{formatPrice(subtotalAmount / 1000)}</span>}
      />
      <Card.Body style={{ padding: '0' }}>
        {
          // 不可编辑，并且无付款信息时
          !editable && !payments.length &&
          <div className="no-payment">
            <i className="no-payment-icon"></i>
            <div className="no-payment-text">{placeholder}</div>
          </div>
        }

        {
          payments.map((item, index) => (
            <div className="payment-item" key={item + index}>
              {
                !!editable && item.paymentTypePkid !== giftPaymentPkid && editAddBtnFlag && (
                  // disabled不可编辑
                  item.disabled ? <i className="remove-btn remove-btn-disabled iconfont icon-remove"></i> : <ReactTouchEvents touchClass="active" onTap={removePayment.bind(this, item.paymentTypePkid)}>
                    <i className="remove-btn iconfont icon-remove"></i>
                  </ReactTouchEvents>
                )
              }
              <div className="payment-detail">
                <span>{paymentMethods[item.paymentTypePkid] ? paymentMethods[item.paymentTypePkid].paymentTypeName : ''}</span>
                {
                  (!!editable && item.paymentTypePkid !== giftPaymentPkid) && !item.disabled
                    ? <input type="number" className="payment-value" value={item.payMoney}
                      onChange={onAmountChange.bind(this, item.paymentTypePkid)}
                      onKeyPress={onKeypress}
                      placeholder="请输入金额"
                    />
                    : <span className="payment-value">{'￥' + formatPrice(item.payMoney)}</span>
                }
              </div>
            </div>
          )
          )
        }

        {
          !!editable && !!payment.length && editAddBtnFlag &&
          <Picker
            data={payment}
            cols={1}
            titlel="选择付款信息"
            onChange={addPayment}
          >
            <div className="new-payment">
              <ReactTouchEvents touchClass="active">
                <i className="add-btn iconfont icon-plus"></i>
              </ReactTouchEvents>
            </div>
          </Picker>
        }

      </Card.Body>
    </Card>
  )
}


function formatPaymentMethods(paymentMethods) {
  return Object.keys(paymentMethods).map(key => {
    return {
      label: paymentMethods[key].paymentTypeName,
      value: paymentMethods[key].pkid,
    }
  })
}
