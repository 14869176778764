import React, { useEffect, useState } from 'react'
import { Card, Checkbox, Toast, List, Button } from 'antd-mobile'
import { formatPrice } from "../../lib/utils"
import { formatDate } from '../../lib/date'
import FooterButtons from "../../components/FooterButtons"
import ReactTouchEvents from 'react-touch-events'
import './style.less'
const CheckboxItem = Checkbox.CheckboxItem;
export default function OrderInfo({ confirmGoods, orderLines, selectDate, goodsCategories }) {

  const [rows, setRows] = useState([...orderLines]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // 路线分组
  let translineGroups = {}
  orderLines.forEach(item => {
    const translineId = item.translineId
    if (!translineGroups[translineId]) {
      translineGroups[translineId] = {
        isRefrigeration: 0,
        totalPrice: 0,
        translineName: item.translineName,
        orderlines: [],
        groups: {}
      }
    }
    translineGroups[translineId].orderlines.push(item)
  })

  Object.keys(translineGroups).map(k => {
    translineGroups[k].orderlines.forEach(item => {
      const category = item.goodsCategory

      if (!translineGroups[k].groups[category]) {
        translineGroups[k].groups[category] = {
          name: goodsCategories[category] ? goodsCategories[category].codeValue : '其他',
          amountSubtotal: 0, // 小计金额，以分为单位
          qtySubtotal: 0,
          goods: []
        }
      }

      translineGroups[k].groups[category].goods.push(item)
      translineGroups[k].groups[category].amountSubtotal += Math.round(item.goodsPriceSubtotal * 1000)
      translineGroups[k].groups[category].qtySubtotal += item.goodsQty
      // 判断是否冷藏。只要配送单中任一商品名称以 L 开头，即判定配送单为冷藏
      translineGroups[k].isRefrigeration = (translineGroups[k].isRefrigeration | (item.goodsName.startsWith('L'))) & 1
      translineGroups[k].totalPrice = translineGroups[k].orderlines.reduce((prev, current) => prev + Math.round((current.goodsPriceSubtotal || 0) * 1000), 0)
    })
  })

  // 所有商品的总价，以分为单位
  const totalPrice = orderLines.reduce((prev, current) => prev + Math.round((current.goodsPriceSubtotal || 0) * 1000), 0)

  // 选中退货货品
  const onChange = (e, item) => {
    const arr = rows.map(d => {
      if (d.pkid === item.pkid) {
        return { ...d, checked: e }
      } else {
        return { ...d }
      }
    })
    setRows(arr)
  }

  // 确认选择退货商品
  const confirm = () => {

    const goods = rows.filter(f => f.checked)
    if (goods && goods.length) {
      Toast.loading('请稍等...', 0.35, () => {
        confirmGoods && confirmGoods({ date: formatDate(selectDate), returnGoods: goods, })
      })
    } else {
      Toast.fail('请先选择要退货货品！', 2)
    }
  }

  // 渲染底部的操作按钮
  const renderFooterButtons = () => {
    return (
      <FooterButtons style={{ zIndex: 100 }}>
        <ReactTouchEvents touchClass="active" onTap={confirm}>
          <button className="footer-btn btn-primary" >确定</button>
        </ReactTouchEvents>
      </FooterButtons>
    )
  }

  return (
    <div className='orderInfo'>
      <Card>
        <Card.Header
          title={<span>{formatDate(selectDate)}</span>}
          extra={<span className="card-extra">总计：￥{formatPrice(totalPrice / 1000)}</span>}
        />
        <Card.Body>
          <>
            {
              Object.keys(translineGroups).map(trans => {
                return (
                  <div key={trans} className='lines'>
                    <div className="orderInfo-title orderInfo-trans">
                      {translineGroups[trans].translineName} {(translineGroups[trans].isRefrigeration ? <span style={{ fontSize: '12px', color: '#FB782F' }}>(*冷藏)</span> : null)}
                    </div>
                    <div>
                      {
                        Object.keys(translineGroups[trans].groups).map(cate => {
                          return (<div key={cate}>
                            <div className="orderInfo-title">{translineGroups[trans].groups[cate].name}</div>
                            <List>
                              {translineGroups[trans].groups[cate].goods.map((item, index) => {
                                return (
                                  <CheckboxItem key={item.pkid} disabled={item.disabled} defaultChecked={item.disabled} onChange={(e) => onChange(e.target.checked, item)}>
                                    <div className="goods-name">
                                      {item.goodsName}
                                      {item.lotNo ? ` (${item.lotNo})` : ''}
                                    </div>
                                    <div className='goods-info'>
                                      <div className='left'>
                                        {'￥' + (item.goodsPrice || '-')}
                                      </div>
                                      <div className='center'>
                                        {(item.goodsQty || '-') + '' + item.goodsUnit}
                                      </div>
                                      <div className='right'>
                                        {'￥' + formatPrice(item.goodsPriceSubtotal)}
                                      </div>
                                    </div>

                                  </CheckboxItem>
                                )
                              })}
                              <div className='goods-subtotal'>
                                <div className='left'>合计</div>
                                <div className='center'>{translineGroups[trans].groups[cate].qtySubtotal}</div>
                                <div className='right'>{'￥' + formatPrice((translineGroups[trans].groups[cate].amountSubtotal || 0) / 1000)}</div>
                              </div>
                            </List>
                          </div>)
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
          </>
        </Card.Body>
      </Card>
      {/* 底部留白 */}
      <div style={{ height: '70px' }}></div>
      {/* 底部操作按钮*/}
      {renderFooterButtons()}
    </div>
  )
}