/**
 * 格式化 Date 对象至 YYYY-MM-DD 格式
 *
 * @param {Date} date
 * @return {String}
 */
export function formatDate(date) {
  return `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`
}

/**
 * 格式化 Date 对象至 YYYY-MM-DD HH:mm 格式
 *
 * @param {Date} date
 * @return {String}
 */
export function formatDateTime(date) {
  const d = formatDate(date)
  return `${d} ${padZero(date.getHours())}:${padZero(date.getMinutes())}`
}


function padZero(num) {
  return (num > 9 ? '' : '0') + num
}
