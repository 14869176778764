import React from 'react'
import ReactTouchEvents from 'react-touch-events'
import { Toast } from 'antd-mobile'
import * as auth from '../../lib/auth'
import * as loginApi from '../../lib/API/login'
import './style.less'

export default class Login extends React.Component {
  constructor(props) {
    super(props);

    // 发送验证码倒计时的定时器
    this.countingDownTimer = null

    this.state = {
      mobile: '',
      vc: '',
      isCountingDown: false,
      countDownValue: 60,
    }
  }

  componentDidMount() {
    window.document.title = '登录'
  }

  async login() {
    const { mobile, vc } = this.state

    if (!mobile) {
      Toast.info('手机号不能为空', 2, null, false)
      return
    }
    if (!vc) {
      Toast.info('验证码不能为空', 2, null, false)
      return
    }

    Toast.loading('加载中...', 0)

    const resp = await loginApi.login(mobile, vc)
    if (resp.isOK) {
      Toast.success('登录成功', 1, null, false)
      // 写入认证信息到本地存储
      auth.setAuthInfo(resp.data.row.Authorization)

      this.props.history.replace('/delivery')

    } else {
      Toast.fail(resp.errorMessage, 2)
    }


  }

  async sendVC() {
    if (this.state.isCountingDown) {
      return
    }

    const mobile = this.state.mobile
    if (!mobile) {
      Toast.info('手机号不能为空', 2, null, false)
      return
    }

    const resp = await loginApi.sendVC(mobile)
    if (resp.isOK) {
      Toast.success('验证码发送成功', 1, null, false)

      this.setState({
        isCountingDown: true,
        countDownValue: 60,
      })

      if (this.countingDownTimer) clearInterval(this.countingDownTimer)

      this.countingDownTimer = setInterval(() => {
        const value = this.state.countDownValue - 1
        this.setState({ countDownValue: value })
        if (value === 0) {
          this.setState({
            isCountingDown: false,
          })
          clearInterval(this.countingDownTimer)
          this.countingDownTimer = null
        }
      }, 1000)

    } else {
      Toast.fail(resp.errorMessage, 2)
    }
  }

  onKeyPress = (event) => {
    if (event.charCode === 13) {
      event.target.blur()
    }
  }

  componentDidCatch(error, errorInfo) {
    Toast.hide()
    Toast.fail(error.message, 2)
  }

  render() {
    const { mobile, vc, isCountingDown, countDownValue } = this.state
    return (
      <div className="page-login">
        <div className="logo-area">
          <div className="logo"></div>
          <div className="name">龙腾行动</div>
        </div>

        <div className="login-card">
          <div className="field">
            <label htmlFor="mobile">手机号</label>
            <input id="mobile" type="text" placeholder="请输入手机号"
              value={mobile}
              onChange={(evt) => this.setState({
                mobile: evt.target.value,
              })}
              onKeyPress={this.onKeyPress}
            />
          </div>
          <div className="field">
            <label htmlFor="vc">验证码</label>
            <div className="vc-area">
              <input id="vc" type="text" placeholder="请输入验证码"
                value={vc}
                onChange={(evt) => this.setState({
                  vc: evt.target.value,
                })}
                onKeyPress={this.onKeyPress}
              />
              <ReactTouchEvents touchClass="active" onTap={this.sendVC.bind(this)}>
                <button
                  disabled={isCountingDown}
                >{
                    isCountingDown
                      ? `${countDownValue}秒后可重新获取`
                      : '发送验证码'
                  }</button>
              </ReactTouchEvents>
            </div>

          </div>

          <ReactTouchEvents touchClass="active" onTap={this.login.bind(this)}>
            <button className="login-btn">登录</button>
          </ReactTouchEvents>
        </div>
      </div>
    )
  }
}
