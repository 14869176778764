import React from 'react'
import { Toast, Modal } from "antd-mobile"
import NavBar from "../../components/NavBar"
import DebtInfo from "../../components/DebtInfo"
import PaymentInfo from "../../components/PaymentInfo"
import * as workCreditApi from '../../lib/API/workCredit'
import * as paymentMethods from "../../lib/paymentMethods"
import ReactTouchEvents from "react-touch-events"
import FooterButtons from "../../components/FooterButtons"
import { moneyAdd } from "../../lib/utils"
import './style.less'
import { TASK_STATUS } from '../../lib/constants'
const task_status = window.localStorage.getItem('task_status') || ''
export default class DebtDetail extends React.Component {
  constructor(props) {
    super(props);

    this.customerId = props.match.params.customerId

    this.state = {
      details: {},
      creditPayments: [],
      creditPaymentMethods: {},
      editable: window.localStorage.getItem('task_status') !== TASK_STATUS.DONE,
      remainAmount: 0, // 待还总额
    }

  }

  componentDidMount() {
    window.document.title = '欠款补齐'

    this.loadDebtDetails()

    // 加载欠款付款方式数据
    paymentMethods.getCreditPaymentMethods()
      .then(methods => {
        this.setState({ creditPaymentMethods: methods })
      })
  }

  loadDebtDetails = () => {
    Toast.loading('加载中...', 0)
    workCreditApi.details(this.customerId)
      .then(resp => {
        Toast.hide()
        if (resp.isOK) {
          const detail = resp.data.row || {}

          this.setState({
            details: detail,
            remainAmount: moneyAdd(...(detail.creditStats || []).map(item => item.creditMoneyRemain)),
          })

        } else {
          Toast.fail(resp.errorMessage)
        }
      })
  }

  savePayments = () => {
    let { creditPayments } = this.state

    if (!creditPayments.length) {
      Toast.fail('请填写欠款付款信息', 2, null, false)
      return
    }

    // 检查当前的支付信息，有未填写金额的项目时，将提示错误并禁止提交
    if (creditPayments.find(item => !item.payMoney || +item.payMoney <= 0)) {
      Toast.fail('请正确填写收款金额', 2, null, false)
      return
    }

    // 整理付款信息中的值
    creditPayments = creditPayments.map(item => {
      item.payMoney = +item.payMoney
      return item
    })
    this.setState({ creditPayments })

    Modal.alert('提示', '是否确认保存欠款收款信息？', [
      { text: '取消', onPress: () => console.log('cancel') },
      {
        text: '确定', onPress: () => {
          Toast.loading('加载中...', 0)
          workCreditApi.keepCredit({
            creditPayments: creditPayments,
            customerId: this.customerId,
          }).then((resp) => {
            Toast.hide()
            if (!resp.isOK) {
              Toast.fail(resp.errorMessage, 2)
              return
            }

            Toast.success('保存欠款收款信息成功', 2)
            this.setState({
              editable: false,
            })
            // 保存成功之后，重新拉取欠款信息数据
            this.loadDebtDetails()

          }).catch(error => {
            Toast.hide()
            Toast.fail(error.message, 2, null, false)
          })
        }
      },
    ])
  }

  // 渲染底部的操作按钮
  renderFooterButtons = () => {
    const { remainAmount, editable } = this.state
    return <FooterButtons>
      <ReactTouchEvents touchClass="active" onTap={() => this.props.history.goBack()}>
        <button className="footer-btn">返回</button>
      </ReactTouchEvents>
      {
        editable && remainAmount > 0 &&
        <ReactTouchEvents touchClass="active" onTap={this.savePayments}>
          <button className="footer-btn btn-primary">保存</button>
        </ReactTouchEvents>
      }
    </FooterButtons>
  }

  render() {
    const { details, editable, creditPayments, creditPaymentMethods, remainAmount } = this.state

    return (
      <div className="debt-detail">
        <NavBar title="欠款补齐" back />

        <div className="debt-content">
          <PaymentInfo
            editAddBtnFlag={true}
            title="欠款付款信息"
            placeholder="暂无付款信息"
            paymentMethods={creditPaymentMethods}
            payments={creditPayments}
            editable={editable && remainAmount > 0}
            onPaymentsChange={(val) => this.setState({ creditPayments: val })}
          />

          <DebtInfo
            creditStats={details.creditStats}
          />
        </div>

        {/* 底部留白 */}
        <div style={{ height: '70px' }}></div>

        {/* 底部操作按钮*/}
        { this.renderFooterButtons()}
      </div>
    )
  }
}
