import {HashRouter, Switch, Route} from 'react-router-dom'
import Home from '../pages/Home'
import Login from '../pages/login/index.jsx'
import Delivery from '../pages/delivery/index'
import WorkOrder from '../pages/workOrder/index'
import Mine from '../pages/mine/index'
// import Navigate from '../pages/navigate/index'
import OrderMap from '../pages/orderMap/index'
import DebtWorkOrder from "../pages/debtWorkOrder"
import DelayWorkOrder from "../pages/delayWorkOrder"
import DebtDetail from "../pages/debtDetail"

export default function Router() {

  return (
    <HashRouter>
      <Switch>
        {/* 登录页 */}
        <Route path="/login" component={ Login } />

        {/* 配送页 */}
        <Route path="/delivery" component={ Delivery } />

        {/* 任务详情 */}
        <Route path="/work-order/:id" component={ WorkOrder } />

        {/* 我的 */}
        <Route path="/mine" component={ Mine } />

        {/*<Route path="/navigate/:lon/:lat" component={ Navigate } />*/}

        {/* 路线规划（地图） */}
        <Route path="/order-map/:pkid" component={ OrderMap } />

        {/* 欠款单 */}
        <Route path="/debt-work-orders" component={ DebtWorkOrder } />

        {/* 欠款单详情 */}
        <Route path="/debt/:customerId" component={ DebtDetail} />

        {/* 未配送单 */}
        <Route path="/delay-work-orders" component={ DelayWorkOrder } />

        <Route path="/">
          <NotFound />
        </Route>
      </Switch>

      <Route path="/" component={ Home } />

    </HashRouter>
  )
}

function NotFound() {
  return <h2>404</h2>
}
