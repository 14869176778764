import http from "../http"

// 欠款单
export function list(page, pageSize, keywords = '') {
  return http.get('/work-credit-customer', {
    params: {
      pageNo: page,
      pageSize,
      keyword: keywords,
    }
  })
}

// 获取指定客户的欠账单
export function details(customerId) {
  return http.get(`/work-credit-customer/get-by-customer-id?customerId=${customerId}`)
}

// 欠账单记账
export function keepCredit(orderInfo) {
  return http.post(`/work-credit-customer/keep/credit`, orderInfo)
}

