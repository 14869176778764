import React from "react"
import { useHistory } from 'react-router-dom'
import ReactTouchEvents from 'react-touch-events'
import { Card } from 'antd-mobile'
import { makePhoneCall, normalizePhoneNumber, maskPhoneNumber, formatPrice } from '../../lib/utils'
import { navigateTo } from "../../lib/amap"

/**
 * 用于在欠款单中展示，简易版本的 WorkOrderItem
 */
export default function SimpleWorkOrderItem({ workOrder }) {
  const history = useHistory()

  const goToNavigate = () => {
    navigateTo(workOrder.customerName, workOrder.customerLongitude, workOrder.customerLatitude)
  }

  const onEnsureBtnClick = () => {
    history.push(`/debt/${workOrder.customerId}`)
  }

  return (
    <Card className="c-task-item c-debt-work-order-item">
      <Card.Header
        title={<div style={{ width: '100%' }}>
          <div className="card-extra">欠款金额：￥{formatPrice(workOrder.creditMoneyTotal)}</div>
          <div>{workOrder.customerName}</div>
        </div>}
        icon={<i className="icon-shop" />}
      />
      <Card.Body style={{ padding: '12px 5px 0 15px' }}>
        <div className="task-detail">
          <i className="dot"></i>
          <div className="address">{workOrder.customerAddress}</div>
          {
            workOrder.customerLongitude && workOrder.customerLatitude &&
            <ReactTouchEvents touchClass="active" onTap={goToNavigate}>
              <i className="navigate iconfont icon-navigation"></i>
            </ReactTouchEvents>
          }
        </div>
        <div className="task-detail user-detail">
          <div className="user">{workOrder.customerContactPerson} &nbsp;&nbsp;&nbsp;{maskPhoneNumber(normalizePhoneNumber(workOrder.customerContactNumber))}</div>
          <ReactTouchEvents touchClass="active" onTap={makePhoneCall.bind(this, normalizePhoneNumber(workOrder.customerContactNumber))}>
            <i className="phone iconfont icon-phone"></i>
          </ReactTouchEvents>
        </div>
      </Card.Body>
      <Card.Footer content={
        <ReactTouchEvents touchClass="active" onTap={onEnsureBtnClick}>
          <button className="task-action-btn">欠款补齐</button>
        </ReactTouchEvents>
      } />
    </Card>
  )
}
