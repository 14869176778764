import http from './http'

// 缓存的商品分类。以 ID 为 Key，原始值为 value
let goodsCategories = null
// 以数组表示的，接口返回的原始分类数据
let originalCategoriesData = null

export async function getGoodsCategoriesFromServer() {
  return http.get('/drop-down/goods-category')
}


export async function getGoodsCategories() {
  if (goodsCategories === null) {
    const resp = await getGoodsCategoriesFromServer()
      .catch((err) => {
        console.error(err)
        return null
      })

    if (resp && resp.isOK) {
      originalCategoriesData = resp.data.rows
      goodsCategories = {}

      originalCategoriesData.forEach(item => {
        goodsCategories[item.codeKey] = item
      })

    } else {
      goodsCategories = null
    }
  }

  return goodsCategories
}
