import {Redirect} from 'react-router-dom'
import {getAuthInfo} from '../lib/auth'

/**
 * 每个路由中都会默认加载的页面，用作路由勾子，不渲染具体内容
 */
export default function Home({location}) {
  // 判断当前用户是否登录，未登录时跳转到登录页面，否则跳至任务页面
  const authInfo = getAuthInfo()

  if (location.pathname === '/') {
    return <Redirect to="/login"></Redirect>
  }

  if (authInfo && location.pathname === '/login') {
    return <Redirect to="/delivery?from=login"></Redirect>
  }

  if (!authInfo && location.pathname !== '/login') {
    return <Redirect to="/login"></Redirect>
  }

  return null
}
