import {SearchBar} from "antd-mobile"
import './style.less'

export default function SearchBox(props) {
  return (
    <div className="search-area">
      <SearchBar
        placeholder="输入客户名称关键词搜索"
        { ...props }
      />
    </div>
  )
}
