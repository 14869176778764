import React from 'react'
import { List, Toast, Modal } from 'antd-mobile'
import ReactTouchEvents from 'react-touch-events'
import TabBar from '../../components/TabBar'
import defaultAvatar from '../../assets/default-avatar.jpg'
import debtIcon from '../../assets/debt-icon@2x.png'
import orderIcon from '../../assets/order-icon@2x.png'
import * as loginApi from '../../lib/API/login'
import * as courierApi from '../../lib/API/courier'
import { removeAuthInfo } from '../../lib/auth'
import { makePhoneCall, normalizePhoneNumber } from '../../lib/utils'
import './style.less'

const alert = Modal.alert;
export default class Mine extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      avatar: '',
      userName: '',
      mobile: '',
      line: '',
      invoiceUser: {},
    }
  }

  componentDidMount() {
    window.document.title = '我的'

    this.loadUserInfo()
  }

  onTabChange = (tab) => {
    if (tab === 'delivery') {
      this.props.history.push('/delivery')
    }
  }

  loadUserInfo = () => {
    Toast.loading('加载中...', 0)
    courierApi.my()
      .then(resp => {
        Toast.hide()
        if (resp.isOK) {
          const data = resp.data.row
          this.setState({
            avatar: data.avatarUrl || '',
            userName: data.userName,
            mobile: data.userMobile,
            line: data.workTranslineName,
            invoiceUser: data.invoiceUser,
          })

        } else {
          Toast.fail(resp.errorMessage, 2)
        }
      })
  }

  logout = () => {
    alert('提示', '是否确认退出登录？', [
      { text: '取消', onPress: () => console.log('cancel') },
      {
        text: '确定',
        onPress: () => {
          Toast.loading('加载中...', 0)
          loginApi.logout()
            .then((res) => {
              Toast.hide()
              if (res.isOK) {
                removeAuthInfo()
                Toast.info('您已退出登录', 2)
                this.props.history.replace('/')
              } else {
                Toast.info('退出登录失败', 2)
              }
            })
        }
      },
    ])


  }

  render() {
    const state = this.state

    return (
      <div className="page-mine">
        <div className="user-hero">
          <div className="user-avatar">
            <img src={state.avatar || ''} alt="" onError={() => this.setState({ avatar: defaultAvatar })} />
          </div>
          <div className="user-info">
            <div className="user-name">
              {state.userName || '-'}
              <div style={{ fontSize: '14px' }}>
                {state.mobile || '-'}
              </div>
            </div>
            <div className="invoice-extra">
              <span className='w'>运输线路：</span>{state.line || '-'}
            </div>
            <div className="invoice-extra">
              <span className='w'>开票员：</span>{state.invoiceUser && state.invoiceUser.userName ? `${state.invoiceUser.userName}` : '-'}
            </div>
            <div className="invoice-extra">
              <span className='w'>开票员手机号：</span> {state.invoiceUser && state.invoiceUser.userMobile ?
                <ReactTouchEvents touchClass="active" onTap={makePhoneCall.bind(this, normalizePhoneNumber(state.invoiceUser.userMobile))}>
                  <span style={{ marginRight: 20 }} >{state.invoiceUser.userMobile || '-'}</span>
                </ReactTouchEvents> : '-'}
            </div>

          </div>
        </div>

        <List>
          <List.Item
            thumb={debtIcon}
            arrow="horizontal"
            onClick={() => this.props.history.push('/debt-work-orders')}
          >欠账单</List.Item>
          <List.Item
            thumb={orderIcon}
            arrow="horizontal"
            onClick={() => this.props.history.push('/delay-work-orders')}
          >未配送单</List.Item>
        </List>

        <div className="logout">
          <ReactTouchEvents touchClass="active" onTap={this.logout}>
            <button className="logout-btn">退出登录</button>
          </ReactTouchEvents>
        </div>

        <TabBar currentTab="mine" onChange={this.onTabChange} />
      </div>
    )
  }
}
