import React, { useState } from 'react'
import ReactTouchEvents from 'react-touch-events'
import { formatDate } from '../../lib/date'
import { formatPrice } from "../../lib/utils"


export default function SummaryCard({ summary, statistics, payTypes }) {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className="task-summaries">
      <div className={`summary-card ${expanded ? 'expanded' : ''}`} style={{ height: (expanded && payTypes.length) ? 400 + payTypes.length * 25 : '' }}>
        <div className="date-indicator">{summary.taskDate && formatDate(new Date(summary.taskDate))}</div>
        <div className="data-items">
          <div className="data-item"><span className="num">{optional(summary.customerCountTotal, '-')}</span>总用户数（户）</div>
          <div className="data-item"><span className="num">{formatPrice(summary.moneyTotal)}</span>总配送金额（元）</div>
        </div>
        <div className="split-line"></div>
        <div className="more-items">
          <div className="items-col">
            <div className="data-item">
              <span className="num">{optional(summary.customerCountWait, '-')}</span>
              <span className="desc">未处理客户（户）</span>
            </div>
            <div className="data-item">
              <span className="num">{optional(summary.customerCountDelivered, '-')}</span>
              <span className="desc">已处理客户（户）</span>
            </div>
          </div>
          <div className="items-col">
            <div className="data-item">
              <span className="num">{formatPrice(summary.moneyWait)}</span>
              <span className="desc">未处理金额（元）</span>
            </div>
            <div className="data-item">
              <span className="num">{formatPrice(summary.moneyProcessed)}</span>
              <span className="desc">已处理金额（元）</span>
            </div>
          </div>
        </div>
        <div className="split-line split-line-2"></div>
        <div className="task-statistics">
          <table>
            <tbody>
              {
                statistics.map((item, index) => {
                  return (
                    <tr key={JSON.stringify(item) + index}>
                      <td>{item.customerTypeName}</td>
                      <td>{item.customerCountTotal}户</td>
                      <td>{formatPrice(item.moneyTotal)}元</td>
                      <td>已处理{item.customerCountDelivered}户</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
        {payTypes.length ?
          <>
            <div className="split-line split-line-3"></div>
            <div className="task-paytype" >
              <table>
                <tbody>
                  {
                    payTypes.map((item, index) => {
                      return (
                        <tr key={JSON.stringify(item) + index}>
                          <td style={{ width: '70%' }}>{item.paymentTypeName}</td>
                          <td>{formatPrice(item.payMoney)}元</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </> : ''
        }

        <ReactTouchEvents onTap={() => setExpanded(!expanded)}>
          <div className="expand-arrow"><i className="iconfont icon-expand"></i></div>
        </ReactTouchEvents>

      </div>
    </div>
  )
}

function optional(value, defaultValue) {
  return typeof value === 'undefined' ? defaultValue : value
}
