import ReactTouchEvents from 'react-touch-events'
import { Card } from 'antd-mobile'
import { makePhoneCall, normalizePhoneNumber, maskPhoneNumber } from '../../lib/utils'
import { navigateTo } from "../../lib/amap";
import { ORDER_STATUS } from '../../lib/constants'
import { formatDate } from '../../lib/date'

/**
 * 用于在任务详情中展示，简易版本的 WorkOrderItem，与 WorkOrderItem 共用大部分 CSS 样式
 */
export default function SimpleWorkOrderItem({ workOrder }) {

  const goToNavigate = () => {
    navigateTo(workOrder.customerName, workOrder.customerLongitude, workOrder.customerLatitude)
  }

  const salers = workOrder.salerName ? (workOrder.salerName).split(',') : []
  const salersPhone = workOrder.salerMobileNo ? (workOrder.salerMobileNo).split(',') : []

  return (
    <Card className="c-task-item c-simple-task-item">
      <Card.Header
        title='工单信息'
        extra={`${workOrder.orderStatus === ORDER_STATUS.TERMINATED ? formatDate(new Date(workOrder.taskDate)) : ''}`}
      />
      <Card.Body style={{ padding: '12px 5px 0 15px' }}>
        <div className="task-detail">
          <i className="icon-shop" />
          <div className="shop-name">{workOrder.customerName}</div>
        </div>
        <div className="task-detail">
          <i className="dot" />
          <div className="address">{workOrder.customerAddress}</div>
          {
            workOrder.customerLongitude && workOrder.customerLatitude &&
            <ReactTouchEvents touchClass="active" onTap={goToNavigate}>
              <i className="navigate iconfont icon-navigation" />
            </ReactTouchEvents>
          }
        </div>

        <div className="task-detail user-detail">
          <div className="user">{workOrder.customerContactPerson} &nbsp;&nbsp;&nbsp;{maskPhoneNumber(normalizePhoneNumber(workOrder.customerContactNumber))}</div>
          <ReactTouchEvents touchClass="active" onTap={makePhoneCall.bind(this, normalizePhoneNumber(workOrder.customerContactNumber))}>
            <i className="phone iconfont icon-phone" />
          </ReactTouchEvents>
        </div>
        <div className="task-detail">
          <i className="dot" />
          <div className="shop-name">开票员</div>
        </div>
        <div className="task-detail user-detail salers">
          {salers.length ? <div>{salers.map((d, index) => (
            <span style={{ marginRight: 20 }} key={index}>{d}</span>
          ))}
          </div> : ''}
          {salersPhone.length ? <div>{salersPhone.map((p, i) => (
            <ReactTouchEvents key={i} touchClass="active" onTap={makePhoneCall.bind(this, normalizePhoneNumber(p))}>
              <span style={{ marginRight: 20 }} >{maskPhoneNumber(normalizePhoneNumber(p))}</span>
            </ReactTouchEvents>
          ))}
          </div> : ''}
        </div>
      </Card.Body>
    </Card>
  )
}
