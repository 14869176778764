import React from 'react'
import {ListView, PullToRefresh, Toast} from "antd-mobile"
import NavBar from "../../components/NavBar"
import DebtWorkOrderItem from "../../components/WorkOrderItem/DebtWorkOrderItem"
import SearchBox from "../../components/SearchBox"
import * as workCreditApi from '../../lib/API/workCredit'
import './style.less'


export default class DebtWorkOrder extends React.Component {

  constructor(props) {
    super(props);

    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1.pkid !== row2.pkid,
    })

    this.originalData = []

    const searchParams = new URLSearchParams(props.location.search || '')

    this.state = {
      dataSource,
      page: 1,
      pageSize: 10,
      keywords: searchParams.get('keywords') || '',
      isLoading: false,
      hasMore: true,
      refreshing: false,
    }
  }

  componentDidMount() {
    window.document.title = '欠款单'

    this.initData()
  }

  initData = () => {
    Toast.loading('加载中...', 0)
    this.getData(1, this.state.keywords)
      .then(data => {
        if (!data) return
        this.originalData = data.rows
        this.setState({
          page: data.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(this.originalData),
          isLoading: false,
          hasMore: data.rows.length >= this.state.pageSize,
          refreshing: false,
        })
      })
  }

  onEndReached = () => {
    if (this.state.isLoading || !this.state.hasMore) {
      return
    }

    this.getData(this.state.page + 1, this.state.keywords)
      .then(data => {
        if (!data) return
        this.originalData = [...this.originalData, ...data.rows]
        this.setState({
          page: data.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(this.originalData),
          isLoading: false,
          hasMore: data.rows.length >= this.state.pageSize,
          refreshing: false,
        })
      })
  }

  getData = (page, keywords = '') => {
    this.setState({ isLoading: true })
    const {pageSize} = this.state

    return workCreditApi.list(page, pageSize, keywords).then(resp => {
      Toast.hide()
      if (resp.isOK) {
        return resp.data
      } else {
        this.setState({isLoading: false, refreshing: false})
        Toast.hide()
        Toast.fail(resp.errorMessage, 2)
      }
    })
  }

  onRefresh = () => {
    this.setState({refreshing: true, isLoading: true})
    this.getData(1, this.state.keywords)
      .then(data => {
        if (!data) return
        this.originalData = data.rows
        this.setState({
          page: data.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(this.originalData),
          isLoading: false,
          hasMore: data.rows.length >= this.state.pageSize,
          refreshing: false,
        })
      })
  }

  doSearch = (keywords) => {
    this.setState({keywords})
    this.getData(1, keywords)
      .then(data => {
        if (!data) return
        this.originalData = data.rows
        this.setState({
          page: data.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(this.originalData),
          isLoading: false,
          hasMore: data.rows.length >= this.state.pageSize,
          refreshing: false,
        })
      })
    this.replaceRouteStates({keywords})
  }

  replaceRouteStates = (newStates = {}) => {
    const params = new URLSearchParams(this.props.location.search)
    Object.keys(newStates).forEach(key => params.set(key, newStates[key]))
    this.props.history.replace('/debt-work-orders?' + params.toString())
  }


  render() {
    const {dataSource, pageSize, refreshing, keywords, hasMore} = this.state

    const renderRow = (rowData) => {
      return <DebtWorkOrderItem
        key={rowData.pkid}
        workOrder={rowData}
      />
    }

    return (
      <div className="page-debt-work-order">
        <NavBar title="欠款单" back />

        {/* 搜索栏 */}
        <SearchBox
          value={ keywords }
          onChange={ (keywords) => this.setState({keywords}) }
          onSubmit={ this.doSearch }
          onClear={ this.doSearch.bind(this, '') }
          style={{padding: '8px 6px'}}
        />

        {/* 配送单列表 */}
        <div className="work-order-list">
          {
            dataSource.getRowCount() === 0
              ? <div className="no-data">暂无数据</div>
              : <div className="order-list">
                <ListView
                  ref={el => this.lv = el}
                  dataSource={ dataSource }
                  renderRow={ renderRow }
                  renderFooter={ () => <div style={{textAlign: 'center'}}>{ hasMore ? '加载更多' : '已经到底了' }</div> }
                  pageSize={pageSize}
                  initialListSize={pageSize}
                  useBodyScroll={true}
                  onEndReached={this.onEndReached}
                  pullToRefresh={<PullToRefresh refreshing={ refreshing} onRefresh={this.onRefresh} />}
                />
              </div>
          }
        </div>


      </div>
    )
  }
}
