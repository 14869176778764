import http from "../http"

// 未配送单
export function list(page, pageSize, keywords = '') {
  return http.get('/work-order/delay', {
    params: {
      pageNo: page,
      pageSize,
      keyword: keywords,
    }
  })
}

