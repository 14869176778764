import React from 'react'
import { Toast } from "antd-mobile"
import ReactTouchEvents from 'react-touch-events'
import NavBar from "../../components/NavBar"
import { classname, formatPrice } from "../../lib/utils"
import { getCurrentLocation } from "../../lib/geoLocation"
import { getPendingTasks, getDoneTasks, getChargingStation } from "../../lib/API/delivery"
import { ORDER_STATUS } from "../../lib/constants"
import * as configApi from '../../lib/API/config'
import './style.less'


export default class OrderMap extends React.Component {

  constructor(props) {
    super(props);

    this.map = null
    this.mapInfoWindow = null // TODO 共用的信息窗体
    this.selectedMarker = null // TODO 共用的用于表示选中状态的 Marker

    // 配送单状态与地图 Marker 的类型之间的映射关系
    this.orderStatus2markerType = {
      [ORDER_STATUS.PENDING]: 'blue', // 待配送的订单显示为蓝色
      [ORDER_STATUS.PROCESSING]: 'red', // 配送中的订单显示为红色
      [ORDER_STATUS.DONE]: 'lightgray', // 已完成和中止的订单显示为灰色
      [ORDER_STATUS.TERMINATED]: 'lightgray',
    }

    this.state = {
      pkid: props.match.params.pkid,
      showSmallFloatWindow: true, // 是否显示小号的配送地点的浮动窗口
      lineName: '',
      workOrders: [],
      selectedWorkOrder: null,
      config: {},
      chargingStations: [],
    }
  }

  async componentDidMount() {
    window.document.title = '智能路径规划'

    Toast.loading('加载中...', 0)
    await this.getConfig()
    await this.getWorkOrders()
    await this.getDoneWorkOrders()
    await this.getChargingStationInfo()
    Toast.hide()

    // 对所有的线路进行排序，并重新设置 index
    const sortedWorkOrders = this.state.workOrders.sort((a, b) => {
      if (a.translineSeq === b.translineSeq) return 0
      return a.translineSeq > b.translineSeq ? 1 : -1
    })
    sortedWorkOrders.forEach((item, index) => {
      item.index = index
    })
    this.setState({
      workOrders: sortedWorkOrders,
    })

    let location = await getCurrentLocation()
    if (!location) {
      Toast.fail('暂时无法获取位置信息，请稍后再试')
      this.props.history.goBack()
      return
    }

    const { workOrders, config } = this.state

    // 为 workOrder[0]，即起点，设置坐标
    if (config.LAT && config.LNG) {
      workOrders[0].customerLongitude = config.LNG
      workOrders[0].customerLatitude = config.LAT

    } else {
      workOrders[0].customerLongitude = location.longitude
      workOrders[0].customerLatitude = location.latitude
    }

    this.map = new window.AMap.Map('map-container', {
      resizeEnable: true, //是否监控地图容器尺寸变化
      zoom: 12, //初始化地图层级
      center: [location.longitude, location.latitude],
    });

    window.AMap.plugin([
      'AMap.ToolBar',
      'AMap.Driving',
    ], async () => {
      // 在图面添加工具条控件，工具条控件集成了缩放、平移、定位等功能按钮在内的组合控件
      this.map.addControl(new window.AMap.ToolBar());

      const driving = new window.AMap.Driving({
        policy: window.AMap.DrivingPolicy.LEAST_TIME,
        hideMarkers: true,
        showTraffic: false,
      })

      // 每 14 个点分成一组，分别调用高德地图的导航 API
      const pointGroups = [[]]
      let index = 0

      this.state.workOrders.forEach(item => {
        if (item.orderStatus === ORDER_STATUS.PENDING || item.orderStatus === ORDER_STATUS.PROCESSING) {
          const point = [item.customerLongitude, item.customerLatitude]
          const groupId = Math.floor(index / 14)
          if (typeof pointGroups[groupId] === "undefined") {
            pointGroups[groupId] = []
          }
          pointGroups[groupId].push(point)
          index++
        }
      })
      // 从第 2 组开始，每组添加上一组的终点数据作为起点，用于使路径连续
      if (pointGroups.length > 1) {
        for (let i = 1; i < pointGroups.length; i++) {
          const prev = pointGroups[i - 1]
          pointGroups[i].unshift(prev[prev.length - 1])
        }
      }

      // 在第一组的第一条数据前，和最后一组的最后一条数据后面，分别添加起点和终点位置，即仓库位置
      const start = [workOrders[0].customerLongitude, workOrders[0].customerLatitude]
      pointGroups[0].unshift(start)
      pointGroups[pointGroups.length - 1].push(start)

      const allRoutePath = []

      for (let i = 0; i < pointGroups.length; i++) {
        const result = await this.searchDrivingPath(driving, pointGroups[i])
          .catch(() => null)
        if (result) {
          const steps = result.routes[0].steps
          steps.forEach(item => {
            const path = item.path || []
            allRoutePath.push(...path)
          })
        }
      }

      window.AMapUI.load(['ui/misc/PathSimplifier'], (PathSimplifier) => {
        const pathSimplifierIns = new PathSimplifier({
          zIndex: 50,
          map: this.map,
          getPath(pathData) {
            return pathData.map(item => {
              return [item.lng, item.lat]
            })
          },
          renderOptions: {
            eventSupport: false,
            pathTolerance: 2,
            "pathLineSelectedStyle": {
              "strokeStyle": "#27aa37",
              "borderWidth": 1,
              "borderStyle": "#cccccc",
              "dirArrowStyle": true
            },
          },
        })

        pathSimplifierIns.setData([allRoutePath])
        pathSimplifierIns.setSelectedPathIndex(0)
      })
    })

    window.AMapUI.loadUI([
      'overlay/SimpleMarker',
    ], (SimpleMarker) => {
      // 遍历当前任务，并绘制点
      this.state.workOrders.forEach(item => {
        item.marker = new SimpleMarker({
          iconLabel: item.index === 0 ? '起' : item.index + '',
          iconTheme: 'default',
          iconStyle: item.index === 0 ? 'green' : this.orderStatus2markerType[item.orderStatus],
          map: this.map,
          position: [item.customerLongitude, item.customerLatitude],
          containerClassNames: 'map-marker',
          zIndex: item.index === 0 ? 150 : 100,
        })
        item.marker.on('click', () => {
          // console.log('marker click')
          this.selectMarker(item, true)
          this.state.chargingStations.forEach(d => {
            d.marker.setIconStyle('red')
            d.marker.setzIndex(100)
          })
        }, this)
      })

      console.log(' this.state.chargingStations', this.state.chargingStations)
      this.state.chargingStations.forEach((item, index) => {
        item.index = index
        item.marker = new SimpleMarker({
          iconLabel: '桩',
          iconTheme: 'default',
          iconStyle: 'red',
          map: this.map,
          position: [item.longitude, item.latitude],
          containerClassNames: 'map-marker',
          zIndex: 100,
        })
        item.marker.on('click', () => {
          this.selectMarkerZ(item)
          // 遍历所有标记点，并将其设置为默认属性
          this.state.workOrders.forEach(item => {
            if (item.marker) {
              item.marker.setIconStyle(item.index === 0 ? 'green' : this.orderStatus2markerType[item.orderStatus])
              item.marker.setzIndex(item.index === 0 ? 150 : 100)
            }
          })
        }, this)
      })
    })

    this.mapInfoWindow = new window.AMap.InfoWindow({
      autoMove: true,
      content: '',
      showShadow: true,
      size: new window.AMap.Size(260, 140),
      offset: new window.AMap.Pixel(0, -44),
    })
  }

  

  componentWillUnmount() {
    if (this.map) {
      this.map.destroy()
    }
  }

  // 封装高德的路径规划接口，改为返回 Promise 的形式
  searchDrivingPath(driving, points) {
    return new Promise((resolve, reject) => {
      if (points.length <= 2) {
        driving.search(points[0], points[points.length - 1], (status, result) => {
          // console.log(status, result)
          if (status === 'complete') {
            resolve(result)
          } else {
            reject()
          }
        })

      } else {
        driving.search(points[0], points[points.length - 1], { waypoints: points.slice(1, points.length - 1) }, (status, result) => {
          // console.log(status, result)
          if (status === 'complete') {
            resolve(result)
          } else {
            reject()
          }
        })
      }
    })
  }

  getWorkOrders = async () => {
    return getPendingTasks(this.state.pkid, 1, 10000)
      .then(resp => {
        if (resp.isOK) {
          // 筛选配送单中地址无效的数据
          const workOrders = resp.data.rows
            .filter(item => item.customerLongitude && item.customerLatitude)
            .map(item => {
              item.marker = null // 用于保存地图的 marker 对象
              return item
            })
          // 在配送单最前面添加"起点"
          workOrders.unshift({
            index: 0,
            pkid: 0,
            customerAddress: '起点',
            customerName: '起点',
            marker: null,
            translineSeq: -1,
          })
          // 获取线路名称，从任一配送单中获取均可
          if (workOrders.length > 2) {
            this.setState({
              lineName: workOrders[1].translineName,
            })
          }

          this.setState({
            workOrders,
            showSmallFloatWindow: false,
          })

        } else {
          Toast.fail(resp.errorMessage, 2)
        }
      })
  }

  getChargingStationInfo = async () => {
    return getChargingStation()
      .then(resp => {
        this.setState({
          chargingStations: resp.data.rows || []
        })
      })
  }

  // 获取已经完成的配送单
  getDoneWorkOrders = async () => {
    return getDoneTasks(this.state.pkid, 1, 10000)
      .then(resp => {
        if (resp.isOK) {
          // 筛选配送单中地址无效的数据
          const workOrders = resp.data.rows
            .filter(item => item.customerLongitude && item.customerLatitude)
            .map(item => {
              item.marker = null // 用于保存地图的 marker 对象
              return item
            })
          // 获取线路名称，从任一配送单中获取均可
          if (!this.state.lineName && workOrders.length > 1) {
            this.setState({
              lineName: workOrders[0].translineName,
            })
          }

          this.setState({
            workOrders: this.state.workOrders.concat(workOrders),
            showSmallFloatWindow: false,
          })

        } else {
          Toast.fail(resp.errorMessage, 2)
        }
      })
  }

  getConfig() {
    return configApi.config()
      .then(config => {
        this.setState({
          config,
        })
      })
      .catch(err => {
        Toast.fail(err.message, 2)
      })
  }

  toggleFloatWindow = () => {
    this.setState({ showSmallFloatWindow: !this.state.showSmallFloatWindow })
  }

  buildInfoWindowHtmlContent = (workOrder) => {
    return `<div class="iw-title">${workOrder.customerName}</div>` +
      `<div class="iw-content">` +
      `<p>运输路线：${workOrder.translineName}</p>` +
      `<p>客户类型名称：${workOrder.customerTypeName}</p>` +
      `<p>商品应收总金额：¥${formatPrice(workOrder.totalGoodsPrice)}</p>` +
      `</div>`
  }

  buildInfoWindowHtmlContentZ = (name) => {
    return `<div class='name'>${name}</div>`
  }

  // 选中充电桩
  selectMarkerZ(obj) {
    this.mapInfoWindow.setContent(this.buildInfoWindowHtmlContentZ(obj.customerName))
    this.mapInfoWindow.open(this.map, [obj.longitude, obj.latitude])
    // 设置当前选中的标记点高亮
    this.state.chargingStations.forEach((d, index) => {
      if (index === obj.index) {
        d.marker.setIconStyle('darkblue')
        d.marker.setzIndex(200)
        this.map.setCenter([d.longitude, d.latitude])
      } else {
        d.marker.setIconStyle('red')
        d.marker.setzIndex(100)
      }
    })

  }

  selectMarker(workOrder, hideFloatWindow = false) {
    // TODO 选中 Marker 的方式可以优化为使用可复用的 Marker 直接覆盖在上面，以减少大量 DOM 操作

    if (!workOrder.marker) return

    if (workOrder.index === 0) {
      this.mapInfoWindow.close()

    } else {
      this.mapInfoWindow.setContent(this.buildInfoWindowHtmlContent(workOrder))
      this.mapInfoWindow.open(this.map, [workOrder.customerLongitude, workOrder.customerLatitude])
    }


    // 遍历所有标记点，并将其设置为默认属性
    this.state.workOrders.forEach(item => {
      if (item.marker) {
        item.marker.setIconStyle(item.index === 0 ? 'green' : this.orderStatus2markerType[item.orderStatus])
        item.marker.setzIndex(item.index === 0 ? 150 : 100)
      }
    })
    // 设置当前选中的标记点高亮
    workOrder.marker.setIconStyle('darkblue')
    workOrder.marker.setzIndex(200)
    this.map.setCenter([workOrder.customerLongitude, workOrder.customerLatitude])
    this.setState({ selectedWorkOrder: workOrder })
    if (hideFloatWindow) {
      this.setState({ showSmallFloatWindow: true })
    }
  }

  render() {
    const { workOrders, showSmallFloatWindow, selectedWorkOrder, lineName } = this.state

    return (
      <div className="order-map">
        <NavBar title="智能路线规划" back />

        <div id="map-container" />

        {/* 浮动窗口 */}
        <div className={classname({
          'float-window-wrap': true,
          'is-small': showSmallFloatWindow,
        })}>
          <div className="float-window">
            <ReactTouchEvents touchClass="active" onTap={this.toggleFloatWindow}>
              <div className="control-handler" />
            </ReactTouchEvents>
            <h4 className="line-name">{lineName}</h4>

            <div className="location-list">
              {
                workOrders.map(item => {
                  return (
                    <ReactTouchEvents key={item.pkid} onTap={this.selectMarker.bind(this, item, false)}>
                      <div className={classname({
                        'location-item': true,
                        'is-active': item === selectedWorkOrder,
                      })}>
                        <span className="location-icon">{item.index === 0 ? '起' : item.index}</span>
                        <span className="location-name">{item.customerName}</span>
                      </div>
                    </ReactTouchEvents>
                  )
                })
              }
            </div>
          </div>
        </div>

      </div>
    )
  }
}

