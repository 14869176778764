import http from '../http'



// 开始今日配送
export function start(pkid) {
  return http.post('/work-task/start', {
    pkid,
  })
}

// 结束今日配送
export function end(pkid) {
  return http.post('/work-task/end', {
    pkid,
  })
}

// 获取是否可以修改记账标志
export function getConfig(params) {
  return http.get('/config/get-by-type-and-key', {
    params
  })
}
