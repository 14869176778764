import http from '../http'

// 系统配置
export function config() {
  return http.get('/config')
    .then(resp => {
      if (resp.isOK) {
        const ret = {}
        resp.data.rows.forEach(item => {
          ret[item.configKey] = item.configValue
        })
        return ret

      } else {
        throw new Error(resp.errorMessage)
      }
    })
}
