import http from '../http'

export function login(mobile, vc) {
  return http.post('/auth/app/vc', {
    jpushId: '',
    mobile,
    vc,
  })
}

export function sendVC(mobile) {
  return http.post('/vc/send', {
    mobile,
  })
}

export function logout() {
  return http.post('/auth/app/logout')
}
