import {Toast} from 'antd-mobile'


export function navigateTo(name, lon, lat) {
  // 从 localStorage 中读取用户的位置信息
  let location
  try {
    const lo = window.localStorage.getItem('location') || ''
    location = JSON.parse(lo)

  } catch (err) {
    Toast.fail('暂时无法获取位置信息，请稍后再试')
    return
  }

  const params = {
    "end": {
      "coordinate": {
        "latitude": lat,
        "longitude": lon
      },
      "name": name,
      "poiid": ""
    },
    "start": {
      "coordinate": {
        "latitude": location.latitude,
        "longitude": location.longitude
      },
      "name": location.address,
      "poiid": ""
    },
  };
  if (window.H5_OBJ_NAME) {
    window.H5_OBJ_NAME.naviByAmap(JSON.stringify(params))
  } else {
    console.log(JSON.stringify(params))
  }
}


